import React from 'react';
import Form from 'react-bootstrap/Form';
import WrapperModal from '../shared/Modals/WrapperModal/WrapperModal';
import { TitleAndCloseHeader } from '../shared/Modals/Headers/HeaderFunctions';
import { CloseAndActionFooter } from '../shared/Modals/Footers/FooterFunctions';

const AddCompanyModal = (props) => (
  <WrapperModal
    show={props.data.showAddCompanyModal}
    onClose={props.handlers.handleShowAddCompanyModal}
    header={{
      exist: true,
      component: (
        <TitleAndCloseHeader
          data={{
            title: 'Add New Company',
            handleCloseModal: props.handlers.handleShowAddCompanyModal,
          }}
        />
      ),
    }}
    footer={{
      exist: true,
      component: (
        <CloseAndActionFooter
          data={{
            actionTitle: 'Add Company',
            handleCloseModal: props.handlers.handleShowAddCompanyModal,
            handleActionButton: props.handlers.handleAddCompany,
            isLoading: props.data.companyLoading,
          }}
        />
      ),
    }}
    centered={true}
  >
    <div className="row">
      <div className="col-12">
        <Form onSubmit={props.handlers.handleAddCompany}>
          <div className="row">
            <div className="col-12">
              <Form.Group controlId="companyName">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company name"
                  className={props.data.companyNameError ? 'is-invalid' : ''}
                  onChange={(e) => props.handlers.handleUserInput(e)}
                />
                {props.data.companyNameError && (
                  <Form.Text>Company name is required</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group controlId="companyEmailDomain">
                <Form.Label>Company Email Domain</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company email domain"
                  className={
                    props.data.companyEmailDomainError ? 'is-invalid' : ''
                  }
                  onChange={(e) => props.handlers.handleUserInput(e)}
                />
                {props.data.companyEmailDomainError !== '' && (
                  <Form.Text>{props.data.companyEmailDomainError}</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group controlId="phoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter phone number"
                  className={props.data.phoneNumberError ? 'is-invalid' : ''}
                  onChange={(e) => props.handlers.handleUserInput(e)}
                />
                {props.data.phoneNumberError && (
                  <Form.Text>Phone number is required</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group controlId="companyCountry">
                <Form.Label>Company Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company country"
                  className={props.data.companyCountryError ? 'is-invalid' : ''}
                  onChange={(e) => props.handlers.handleUserInput(e)}
                />
                {props.data.companyCountryError && (
                  <Form.Text>Company country is required</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group controlId="companyFullAddress">
                <Form.Label>Company Full Address</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  type="text"
                  placeholder="Enter company full address"
                  className={
                    props.data.companyFullAddressError ? 'is-invalid' : ''
                  }
                  onChange={(e) => props.handlers.handleUserInput(e)}
                />
                {props.data.companyFullAddressError && (
                  <Form.Text>Company address is required</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group>
                <Form.Label>Payment Type</Form.Label>
                <div className="d-flex">
                  <Form.Check
                    id="paymentType1"
                    type="radio"
                    label="Postpaid"
                    checked={props.data.paymentType}
                    onChange={(e) => props.handlers.handleUserInput(e)}
                  />
                  <Form.Check
                    id="paymentType2"
                    type="radio"
                    label="Prepaid"
                    checked={!props.data.paymentType}
                    onChange={(e) => props.handlers.handleUserInput(e)}
                  />
                </div>
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group>
                <Form.Label>Allow International Messaging</Form.Label>
                <div className="d-flex">
                  <Form.Check
                    id="intMsgAllow1"
                    type="radio"
                    label="No"
                    checked={!props.data.intMsgAllow}
                    onChange={(e) => props.handlers.handleUserInput(e)}
                  />
                  <Form.Check
                    id="intMsgAllow2"
                    type="radio"
                    label="Yes"
                    checked={props.data.intMsgAllow}
                    onChange={(e) => props.handlers.handleUserInput(e)}
                  />
                </div>
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group controlId="billingEmail">
                <Form.Label>Billing Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter billing email"
                  className={props.data.billingEmailError ? 'is-invalid' : ''}
                  onChange={(e) => props.handlers.handleUserInput(e)}
                />
                {props.data.billingEmailError !== '' && (
                  <Form.Text>{props.data.billingEmailError}</Form.Text>
                )}
              </Form.Group>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </WrapperModal>
);

export default AddCompanyModal;
