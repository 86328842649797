import React from 'react';
import moment from 'moment';

const Footer = () => {
  return (
    <div className="Footer">
      <span>
        Copyright©{moment().year()}, Tune Talk Sdn. Bhd. All rights reserved
      </span>
    </div>
  );
};

export default Footer;
