import React from 'react';
import Form from 'react-bootstrap/Form';
// import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import WrapperModal from '../shared/Modals/WrapperModal/WrapperModal';
import { TitleAndCloseHeader } from '../shared/Modals/Headers/HeaderFunctions';
import { CloseAndActionFooter } from '../shared/Modals/Footers/FooterFunctions';

const SendLaterModal = (props) => (
  <WrapperModal
    show={props.data.showSendLaterModal}
    onClose={props.handlers.handleShowSendLaterModal}
    header={{
      exist: true,
      component: (
        <TitleAndCloseHeader
          data={{
            title: 'Schedule SMS',
            handleCloseModal: props.handlers.handleShowSendLaterModal,
          }}
        />
      ),
    }}
    footer={{
      exist: true,
      component: (
        <CloseAndActionFooter
          data={{
            actionTitle: 'Confirm',
            handleCloseModal: props.handlers.handleShowSendLaterModal,
            handleActionButton: props.handlers.handleSendLater,
            isLoading: props.data.sendLaterLoading,
          }}
        />
      ),
    }}
    centered={true}
    styles={'SendLaterModal'}
  >
    <div className="row">
      <div className="col-12">
        <Form onSubmit={props.handlers.handleSendLater}>
          <div className="row">
            <div className="col-12">
              <Form.Group controlId="scheduleName">
                <Form.Label>Campaign Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Campaign Name"
                  className={props.data.scheduleNameError ? 'is-invalid' : ''}
                  onChange={(e) => props.handlers.handleUserInput(e)}
                />
                {props.data.scheduleNameError && (
                  <Form.Text>Campaign name is required</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group controlId="date">
                <Form.Label>Preferred Date & Time</Form.Label>
                <DateRangePicker
                  initialSettings={{
                    parentEl: '.SendLaterModal',
                    singleDatePicker: true,
                    showDropdowns: true,
                    timePicker: true,
                    locale: {
                      cancelLabel: 'Clear',
                      format: 'DD/MM/YYYY hh:mm A',
                    },
                    autoUpdateInput: false,
                  }}
                  onEvent={(event, picker) =>
                    props.handlers.handleDatePicker(event, picker)
                  }
                >
                  <Form.Control
                    type="text"
                    className={
                      'form-control datetime ' +
                      (props.data.preferedDateTimeError ? 'is-invalid' : '')
                    }
                    placeholder="Select Date & Time"
                    aria-invalid="false"
                    autoComplete="off"
                  />
                </DateRangePicker>
                {props.data.preferedDateTimeError && (
                  <Form.Text>Prefered date & time is required</Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group className="file-format">
                <Form.Label>IMPORTANT NOTES</Form.Label>
                <Form.Text as="span">
                  This SMS campaign will be automatically send on your preferred
                  date & time.
                  <br />
                  <br />
                  Switch off the Stop Schedule Icon to stop the SMS campaign.
                  <br />
                  <br />
                  Stop Schedule function is only available when the campaign is
                  in Waiting status.
                  <br />
                  <br />
                  Insuffcient balance in your account will caused failure in SMS
                  delivery
                </Form.Text>
              </Form.Group>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </WrapperModal>
);

export default SendLaterModal;
