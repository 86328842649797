import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import Swal from 'sweetalert2';
import { getRequest, patchRequest } from '../../../helpers/apiHandlers';
import { historyPush } from '../../../routes/historyPush';

/* Components */
import InvoiceDetails from '../../../components/AdminTools/Invoice/InvoiceDetails';
import InvoiceActions from '../../../components/AdminTools/Invoice/InvoiceActions';

/* custom */
const swalCustomConfirmationButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn main-btn ml-3',
    cancelButton: 'btn inverse-btn',
    title: 'invoice-title',
  },
  buttonsStyling: false,
});

class ViewInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceNo: '',
      issueDate: null,
      dueDate: null,
      invoiceTitle: '',
      billFrom: {},
      billTo: {},
      item: '',
      cost: 0,
      quantity: 0,
      price: 0,
      description: '',
      discount: 0,
      discountAmount: 0,
      tax: 0,
      taxAmount: 0,
      footer: '',
      subtotal: 0,
      total: 0,
      paidAt: null,
      sentAt: null,

      /* flags */
      isLoading: false,
      paidLoading: false,
      sendLoading: false,
      showItem: false,
      from: 'view',
    };

    this.getInvoiceData = this.getInvoiceData.bind(this);
    this.handleSendInvoice = this.handleSendInvoice.bind(this);
    this.hitSendInvoice = this.hitSendInvoice.bind(this);
    this.handlePaidInvoice = this.handlePaidInvoice.bind(this);
  }

  componentDidMount() {
    const { params } = this.props.match;

    if (params.id) {
      this.getInvoiceData(params.id);
    }
  }

  getInvoiceData(id) {
    this.setState({ isLoading: true });

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    };

    getRequest(`/admin/invoices/${id}`, data, (res) => {
      // console.log(res);
      if (res.success) {
        this.setState({
          invoiceNo: res.data.invNumber,
          issueDate: res.data.dateIssue,
          dueDate: res.data.dueDate,
          invoiceTitle: res.data.title,
          billFrom: res.data.billFrom,
          billTo: res.data.billTo,
          item: res.data.item,
          cost: res.data.cost,
          quantity: res.data.quantity,
          price: res.data.price,
          description: res.data.desc,
          discount: res.data.discount,
          discountAmount: res.data.discountAmount,
          tax: res.data.tax,
          taxAmount: res.data.taxAmount,
          footer: res.data.footer,
          subtotal: res.data.subtotal,
          total: res.data.total,
          paidAt: res.data.paidAt,
          sentAt: res.data.sentAt,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
        cogoToast.error(res.message, { position: 'top-right' });
        historyPush('/dashboard/admin-tools/invoice');
      }
    });
  }

  handleSendInvoice() {
    this.setState({ sendLoading: true });

    if (this.state.sentAt === null) {
      this.hitSendInvoice();
    } else {
      swalCustomConfirmationButtons
        .fire({
          title: `This invoice has already been sent on <br /> ${moment(
            this.state.sentAt
          ).format('DD MMMM YYYY, h:mm:ss a')}`,
          text: 'Are you sure you want to resend it?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Yes, proceed',
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.hitSendInvoice();
          }
          this.setState({ sendLoading: false });
        });
    }
  }

  hitSendInvoice() {
    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    };

    getRequest(
      `/admin/invoices/send/${this.props.match.params.id}`,
      data,
      (res) => {
        this.setState({ sendLoading: false });
        if (res.success) {
          cogoToast.success(res.message, { position: 'top-right' });
        } else {
          cogoToast.error(res.message, { position: 'top-right' });
        }
      }
    );
  }

  handlePaidInvoice() {
    this.setState({ paidLoading: true });
    patchRequest(
      `/admin/invoices/paid/${this.props.match.params.id}`,
      {},
      this.props.auth.token,
      (res) => {
        this.setState({ paidLoading: false });
        if (res.success) {
          cogoToast.success(res.message, { position: 'top-right' });
          historyPush('/dashboard/admin-tools/invoice');
        } else {
          this.setState({ paidLoading: false });
          cogoToast.error(res.message, { position: 'top-right' });
        }
      }
    );
  }

  render() {
    const { handleSendInvoice, handlePaidInvoice } = this;
    return (
      <div className="ViewInvoice">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-9">
                <InvoiceDetails data={this.state} />
              </div>
              <div className="col-12 col-md-3">
                <InvoiceActions
                  data={this.state}
                  handlers={{ handleSendInvoice, handlePaidInvoice }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ViewInvoice);
