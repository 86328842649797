import React from 'react';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import { Table } from 'react-bootstrap';
import Loading from '../shared/Loading/Loading';

const AdminReportTable = ({ data, handlePageChange }) => (
  <div className="col-12 mt-5">
    <Table responsive>
      <thead>
        <tr>
          <th>Timestamp</th>
          <th>Partner Name</th>
          <th>Shortcode</th>
          <th>Destination</th>
          <th>Message ID</th>
          <th>SMS Content</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {data.isLoading && (
          <tr>
            <td colSpan="7" className="loading-white-bg">
              <Loading />
            </td>
          </tr>
        )}
        {!data.isLoading &&
          data.reportDataList.length > 0 &&
          data.reportDataList.map((report) => (
            <tr key={report.id}>
              <td>
                {moment(report.shouldRunAt).format('DD MMMM YYYY, h:mm:ss a')}
              </td>
              <td>{report.companyName}</td>
              <td>{report.shortcode}</td>
              <td>{report.destination}</td>
              <td>{report.messageId}</td>
              <td>{report.content}</td>
              <td className="badge-wrapper">
                <span
                  className={
                    'badge badge-pill ' +
                    (report.status === 'success' ||
                    report.status === 'delivered'
                      ? 'badge-light-success'
                      : report.status === 'pending' ||
                        report.status === 'waiting'
                      ? 'badge-light-warning'
                      : 'badge-light-danger')
                  }
                >
                  {report.status}
                </span>
              </td>
            </tr>
          ))}
        {!data.isLoading && data.reportDataList.length === 0 && (
          <tr className="text-center">
            <td colSpan="7">
              <img
                alt="no-data-found"
                src={require('../../assets/shared/no-data.png').default}
              />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
    {data.totalData > 10 && (
      <div className="pagination-custom-style">
        <Pagination
          activePage={data.currentPage}
          itemsCountPerPage={data.perPage}
          totalItemsCount={data.totalData}
          onChange={(e) => handlePageChange(e)}
          hideFirstLastPages
          prevPageText="<PREV"
          nextPageText="NEXT>"
          activeClass="active-page"
          activeLinkClass="active-link"
          itemClass=""
          linkClass="defaul-link"
        />
      </div>
    )}
  </div>
);

export default AdminReportTable;
