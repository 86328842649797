import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Page404 from '../components/shared/Page404/Page404';
import UserManagement from '../layouts/AdminTools/UserManagement';
import AdminReport from '../layouts/AdminTools/AdminReport';
import TopupHistory from '../layouts/AdminTools/TopupHistory';
import AdminActivityLog from '../layouts/AdminTools/AdminActivityLog';
import InvoiceRoutes from '../routes/InvoiceRoutes';

const baseUrl = process.env.PUBLIC_URL;

const Routes = () => (
  <div className="AdminTools">
    <Switch>
      <Redirect
        exact
        from={`${baseUrl}/dashboard/admin-tools`}
        to={`${baseUrl}/dashboard/admin-tools/user-management`}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/dashboard/admin-tools/user-management`}
        component={UserManagement}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/dashboard/admin-tools/admin-report`}
        component={AdminReport}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/dashboard/admin-tools/topup-history`}
        component={TopupHistory}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/dashboard/admin-tools/admin-activity-log`}
        component={AdminActivityLog}
      />
      <PrivateRoute
        path={`${baseUrl}/dashboard/admin-tools/invoice`}
        component={InvoiceRoutes}
      />
      <Route path="*" component={Page404} />
    </Switch>
  </div>
);

export default Routes;
