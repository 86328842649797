import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Card, Form } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { getRequest } from '../../../helpers/apiHandlers';
import Loading from '../../../components/shared/Loading/Loading';
import WrapperCard from '../../../components/shared/Cards/WrapperCard';
import InvoiceTable from '../../../components/AdminTools/Invoice/InvoiceTable';

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createdStartDate: null,
      createdEndDate: null,
      dueStartDate: null,
      dueEndDate: null,
      createdDateValue: '',
      dueDateValue: '',
      companyName: '',
      invoiceData: [],
      isLoading: false,
      currentPage: 1,
      perPage: 10,
      totalData: 0,
    };

    this.handleShowInvoiceData = this.handleShowInvoiceData.bind(this);
    this.handleCreatedDatePicker = this.handleCreatedDatePicker.bind(this);
    this.handleDueDatePicker = this.handleDueDatePicker.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.handleShowInvoiceData();
  }

  handleShowInvoiceData(showData) {
    this.setState({ isLoading: true });

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
      params: {
        page: showData ? 1 : this.state.currentPage,
        perPage: this.state.perPage,
      },
    };

    if (
      this.state.createdStartDate !== null &&
      this.state.createdEndDate !== null
    ) {
      data.params.createdStartDate = moment(
        this.state.createdStartDate
      ).toISOString();
      data.params.createdEndDate = moment(
        this.state.createdEndDate
      ).toISOString();
    }

    if (this.state.dueStartDate !== null && this.state.dueEndDate !== null) {
      data.params.dueDateStartDate = moment(
        this.state.dueStartedDate
      ).toISOString();
      data.params.dueDateEndDate = moment(this.state.dueEndDate).toISOString();
    }

    if (this.state.companyName.trim() !== '') {
      data.params.companyName = this.state.companyName;
    }

    getRequest('/admin/invoices', data, (res) => {
      // console.log(res);
      if (res.success) {
        this.setState({
          invoiceData: res.data.invoice,
          currentPage: res.data.pagination.currentPage,
          perPage: res.data.pagination.perPage,
          totalData: res.data.pagination.totalData,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  handleUserInput(e) {
    const { id, value } = e.target;

    this.setState({ [id]: value });
  }

  handleCreatedDatePicker(e, p) {
    if (e.type === 'apply') {
      const dateValue = p.element.val(
        p.startDate.format('DD/MM/YYYY hh:mm A') +
          ' - ' +
          p.endDate.format('DD/MM/YYYY hh:mm A')
      );

      this.setState({
        createdDateValue: dateValue,
        createdStartDate: p.startDate,
        createdEndDate: p.endDate,
      });
    }

    if (e.type === 'cancel') {
      const dateValue = p.element.val('');

      this.setState({
        createdDateValue: dateValue,
        createdStartDate: null,
        createdEndDate: null,
      });
    }
  }

  handleDueDatePicker(e, p) {
    if (e.type === 'apply') {
      const dateValue = p.element.val(
        p.startDate.format('DD/MM/YYYY hh:mm A') +
          ' - ' +
          p.endDate.format('DD/MM/YYYY hh:mm A')
      );

      this.setState({
        dueDateValue: dateValue,
        dueStartDate: p.startDate,
        dueEndDate: p.endDate,
      });
    }

    if (e.type === 'cancel') {
      const dateValue = p.element.val('');

      this.setState({
        dueDateValue: dateValue,
        dueStartDate: null,
        dueEndDate: null,
      });
    }
  }

  handlePageChange(pageNo) {
    this.setState(
      {
        currentPage: pageNo,
      },
      () => {
        this.handleShowInvoiceData();
      }
    );
  }

  render() {
    return (
      <div className="InvoiceList">
        <div className="row">
          <div className="col-12">
            <WrapperCard
              header={{
                exist: true,
                data: {
                  iconName: 'bx bx-receipt',
                  title: 'ADMIN - Invoice List',
                },
              }}
            >
              <Card.Body>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Form.Group controlId="createdDateRange">
                      <Form.Label>Invoice Created Date & Time Range</Form.Label>
                      <DateRangePicker
                        initialSettings={{
                          timePicker: true,
                          locale: {
                            cancelLabel: 'Clear',
                            format: 'DD/MM/YYYY hh:mm A',
                          },
                          autoUpdateInput: false,
                        }}
                        onEvent={(event, picker) =>
                          this.handleCreatedDatePicker(event, picker)
                        }
                      >
                        <Form.Control
                          type="text"
                          className="form-control datetime"
                          placeholder="Select Date & Time"
                          aria-invalid="false"
                          autoComplete="off"
                        />
                      </DateRangePicker>
                    </Form.Group>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Group controlId="dueDateRange">
                      <Form.Label>Registered Due Date & Time Range</Form.Label>
                      <DateRangePicker
                        initialSettings={{
                          timePicker: true,
                          locale: {
                            cancelLabel: 'Clear',
                            format: 'DD/MM/YYYY hh:mm A',
                          },
                          autoUpdateInput: false,
                        }}
                        onEvent={(event, picker) =>
                          this.handleDueDatePicker(event, picker)
                        }
                      >
                        <Form.Control
                          type="text"
                          className="form-control datetime"
                          placeholder="Select Date & Time"
                          aria-invalid="false"
                          autoComplete="off"
                        />
                      </DateRangePicker>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <Form.Label>Filter By</Form.Label>
                    <Form.Group controlId="companyName">
                      <Form.Control
                        placeholder="Company Name"
                        type="text"
                        onChange={(e) => this.handleUserInput(e)}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Card.Body>
              <Card.Body className="pt-0">
                <div className="row">
                  <div className="col-12 d-flex flex-sm-row flex-column justify-content-center">
                    <button
                      type="submit"
                      className="btn main-btn"
                      onClick={() => this.handleShowInvoiceData(true)}
                      disabled={this.state.isLoading}
                    >
                      {this.state.isLoading ? <Loading /> : <span>Apply</span>}
                    </button>
                  </div>
                  <InvoiceTable
                    data={this.state}
                    handlePageChange={this.handlePageChange}
                  />
                </div>
              </Card.Body>
            </WrapperCard>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Invoice);
