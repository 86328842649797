import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Card, Form, Dropdown } from 'react-bootstrap';

/* components */
import WrapperCard from '../../shared/Cards/WrapperCard';
import HOC from '../../shared/HOC';
import Loading from '../../shared/Loading/Loading';

/* helpers */
import {
  formatPercentage,
  formatAmount,
} from '../../../helpers/helperFunction';

const InvoiceEditing = ({ data, handlers }) => {
  const price = parseFloat((data.tempCost * data.tempQuantity).toFixed(2)),
    subTotal = price,
    discountAmount = parseFloat((subTotal * data.tempDiscount).toFixed(2)),
    afterDiscount = parseFloat((subTotal - discountAmount).toFixed(2)),
    taxAmount = parseFloat((afterDiscount * data.tempTax).toFixed(2)),
    invoiceTotal = parseFloat((afterDiscount + taxAmount).toFixed(2));

  useEffect(() => {
    if (data.tempSubtotal !== subTotal || data.tempTotal !== invoiceTotal) {
      handlers.handleSetTotal({
        price,
        subTotal,
        discountAmount,
        taxAmount,
        invoiceTotal,
      });
    }
  }, [
    data,
    handlers,
    price,
    subTotal,
    discountAmount,
    taxAmount,
    invoiceTotal,
  ]);

  return (
    <WrapperCard
      header={{
        exist: true,
        data: {
          iconName: 'bx bx-receipt',
          title: 'ADMIN - Edit Invoice',
        },
      }}
    >
      <Card.Body>
        <div className="topSection">
          <div className="row ">
            <div className="col-md-12 col-xl-4">
              <Form.Group controlId="invoiceNo">
                <span className="invoice-number mr-2">Invoice#</span>
                <Form.Control
                  type="text"
                  value={data.invoiceNo}
                  disabled={true}
                  readOnly
                />
              </Form.Group>
            </div>
            <div className="col-md-12 col-xl-8">
              <div className="d-flex align-items-center justify-content-xl-end flex-wrap">
                <div className="mr-5">
                  <small className="text-muted mr-2">Date Issue:</small>
                  <DatePicker
                    selected={data.tempIssueDate}
                    onChange={(date) => handlers.handleIssueDate(date)}
                  />
                </div>
                <div>
                  <small className="text-muted mr-2">Date Due:</small>
                  <DatePicker
                    selected={data.tempDueDate}
                    onChange={(date) => handlers.handleDueDate(date)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row my-3">
          <div className="col-6">
            <Form.Group controlId="tempInvoiceTitle">
              <h4>Invoice</h4>
              <Form.Control
                className={data.tempInvoiceTitleError ? 'is-invalid' : ''}
                type="text"
                value={data.tempInvoiceTitle}
                onChange={(e) => handlers.handleUserInput(e)}
              />
            </Form.Group>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <div className="logo-container">
              <img
                src={require('../../../assets/auth/tt-5g.png').default}
                alt="login-logo"
                className="login-logo"
                width="100"
              />
              <img
                src={require('../../../assets/auth/tt-gobeyond.png').default}
                alt="go-beyond"
                className="go-beyond"
                width="100"
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="row invoice-info">
          <div className="col-6 mt-1">
            <Form.Group>
              <h6 className="invoice-to">Bill To</h6>
              <Form.Control
                className={data.tempBillToCompanyError ? 'is-invalid' : ''}
                type="text"
                value={data.tempBillToCompany}
                disabled={true}
                readOnly
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                className={data.tempBillToAddressError ? 'is-invalid' : ''}
                type="text"
                as="textarea"
                rows="3"
                value={data.tempBillToAddress}
                disabled={true}
                readOnly
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                className={data.tempBillToEmailError ? 'is-invalid' : ''}
                type="text"
                value={data.tempBillToEmail}
                disabled={true}
                readOnly
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                className={data.tempBillToPhoneNumberError ? 'is-invalid' : ''}
                type="number"
                value={data.tempBillToPhoneNumber}
                disabled={true}
                readOnly
              />
            </Form.Group>
          </div>
        </div>
        <hr />
        <div className="invoice-product-details">
          <div className="row">
            <div className="col-3 col-md-4">
              <span>Item</span>
            </div>
            <div className="col-3">
              <span>Cost</span>
            </div>
            <div className="col-3">
              <span>Qty</span>
            </div>
            <div className="col-3 col-md-2">
              <span>Price</span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="invoice-items border rounded mb-1">
                <div className="left-item">
                  <div className="row mt-2 px-2 ">
                    <div className="col-12 col-md-4">
                      <Form.Group controlId="item">
                        <Form.Control
                          type="text"
                          value={data.item}
                          disabled={true}
                          readOnly
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-3">
                      <Form.Group controlId="tempCost">
                        <Form.Control
                          className={data.tempCostError ? 'is-invalid' : ''}
                          type="number"
                          value={data.tempCost}
                          onChange={(e) => handlers.handleUserInput(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-3">
                      <Form.Group controlId="tempQuantity">
                        <Form.Control
                          className={data.tempQuantityError ? 'is-invalid' : ''}
                          type="number"
                          value={data.tempQuantity}
                          onChange={(e) => handlers.handleUserInput(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-2">
                      <Form.Group>
                        <div className="price">
                          <strong>{formatAmount(price)}</strong>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-4">
                      <Form.Group controlId="tempDescription">
                        <Form.Control
                          className={
                            data.tempDescriptionError ? 'is-invalid' : ''
                          }
                          type="text"
                          value={data.tempDescription}
                          onChange={(e) => handlers.handleUserInput(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-8">
                      <Form.Group className="my-2">
                        {/* <span>Discount: </span>
                        <span className="discount-value mr-2">
                          {formatPercentage(data.tempDiscount)}
                        </span> */}
                        <span>Tax: </span>
                        <span className="tax mr-2">
                          {formatPercentage(data.tempTax)}
                        </span>
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="right-item border-left">
                  <div className="invoice-icons">
                    <span className="cursor-pointer" data-repeater-delete="">
                      <i className="bx bx-x" />
                    </span>
                    <Dropdown
                      id="item-dropdown"
                      show={data.showItem}
                      onMouseEnter={handlers.showItemDropdown}
                      onMouseLeave={handlers.hideItemDropdown}
                    >
                      <Dropdown.Toggle as="div">
                        <i
                          className="bx bx-cog cursor-pointer mr-0"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          role="button"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item as="div">
                          <div className="row">
                            {/* <div className="col-12 form-group">
                              <Form.Group controlId="_tempDiscount">
                                <Form.Label>Discount (%)</Form.Label>
                                <Form.Control
                                  className={
                                    data._tempDiscountError ? 'is-invalid' : ''
                                  }
                                  type="number"
                                  placeholder="0"
                                  value={data._tempDiscount}
                                  onChange={(e) => handlers.handleUserInput(e)}
                                />
                              </Form.Group>
                            </div> */}
                            <div className="col-6">
                              <Form.Group controlId="_tempTax">
                                <Form.Label>Tax (%)</Form.Label>
                                <Form.Control
                                  className={
                                    data._tempTaxError ? 'is-invalid' : ''
                                  }
                                  type="number"
                                  placeholder="0"
                                  value={data._tempTax}
                                  onChange={(e) => handlers.handleUserInput(e)}
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-between">
                            <button
                              type="button"
                              className="btn main-btn"
                              onClick={() => handlers.handleSetDiscountTax()}
                            >
                              <span>Apply</span>
                            </button>
                            <button
                              type="button"
                              className="btn inverse-btn ml-1"
                              onClick={() => handlers.hideItemDropdown()}
                            >
                              <span>Cancel</span>
                            </button>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="invoice-subtotal pt-50">
          <div className="row">
            <div className="col-md-5 col-12">
              <div className="row">
                <div className="col-12">
                  <Form.Group controlId="tempFooter">
                    <Form.Control
                      className={data.tempFooterError ? 'is-invalid' : ''}
                      type="text"
                      value={data.tempFooter}
                      onChange={(e) => handlers.handleUserInput(e)}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-7 offset-lg-2 col-12">
              <ul className="list-group list-group-flush">
                <li className="list-group-item d-flex justify-content-between border-0 pb-0">
                  <span className="invoice-subtotal-title">Subtotal</span>
                  <h6 className="invoice-subtotal-value mb-0">
                    {formatAmount(subTotal)}
                  </h6>
                </li>
                {/* <li className="list-group-item d-flex justify-content-between border-0 pb-0">
                  <span className="invoice-subtotal-title">Discount</span>
                  <h6 className="invoice-subtotal-value mb-0">
                    {`- (${formatPercentage(data.tempDiscount)}) ${formatAmount(
                      data.tempDiscountAmount
                    )}`}
                  </h6>
                </li> */}
                <li className="list-group-item d-flex justify-content-between border-0 pb-0">
                  <span className="invoice-subtotal-title">Tax</span>
                  <h6 className="invoice-subtotal-value mb-0">
                    {`(${formatPercentage(data.tempTax)}) ${formatAmount(
                      data.tempTaxAmount
                    )}`}
                  </h6>
                </li>
                <li className="list-group-item py-0 border-0 mt-25">
                  <hr />
                </li>
                <li className="list-group-item d-flex justify-content-between border-0 py-0">
                  <span className="invoice-subtotal-title">Invoice Total</span>
                  <h6 className="invoice-subtotal-value mb-0">
                    {formatAmount(invoiceTotal)}
                  </h6>
                </li>
                {/* <li className="list-group-item d-flex justify-content-between border-0 pb-0">
                  <span className="invoice-subtotal-title">Paid to date</span>
                  <h6 className="invoice-subtotal-value mb-0">
                    - {`MYR ${paidToDate}`}
                  </h6>
                </li> */}
                {/* <li className="list-group-item d-flex justify-content-between border-0 pb-0">
                  <span className="invoice-subtotal-title">Balance</span>
                  <h6 className="invoice-subtotal-value mb-0">{`MYR ${balance}`}</h6>
                </li> */}
                <li className="list-group-item border-0 pb-0">
                  <button
                    className="btn main-btn btn-block"
                    onClick={() => handlers.handleUpdateInvoice()}
                    disabled={data.updateLoading}
                  >
                    {!data.updateLoading ? (
                      <HOC>
                        <i className="bx bx-send" />
                        <span>Update Invoice</span>
                      </HOC>
                    ) : (
                      <Loading />
                    )}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Card.Body>
    </WrapperCard>
  );
};

export default InvoiceEditing;
