import React, { useRef } from 'react';
import Card from 'react-bootstrap/Card';
import ReactToPrint from 'react-to-print';

/* components */
import WrapperCard from '../../shared/Cards/WrapperCard';
import Loading from '../../shared/Loading/Loading';
import HOC from '../../shared/HOC';
import InvoiceDetails from './InvoiceDetails';

const InvoiceActions = ({ data, handlers }) => {
  const componentRef = useRef();

  return (
    <WrapperCard
      header={{
        exist: true,
        data: {
          iconName: 'bx bx-key',
          title: 'Action',
        },
      }}
    >
      <Card.Body>
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              className="btn main-btn icon-btn w-100"
              disabled={data.sendLoading}
              onClick={() => handlers.handleSendInvoice()}
            >
              {!data.sendLoading ? (
                <HOC>
                  <i className="bx bx-send" />
                  <span>Send Invoice</span>
                </HOC>
              ) : (
                <Loading />
              )}
            </button>
          </div>
          <div className="col-12">
            <button
              type="button"
              className={
                'btn inverse-btn icon-btn w-100 mt-3' +
                (data.paidLoading || data.paidAt !== null
                  ? ' btn-disabled'
                  : '')
              }
              disabled={data.paidLoading || data.paidAt !== null}
              onClick={() => handlers.handlePaidInvoice()}
            >
              {!data.paidLoading ? (
                <HOC>
                  <i className="bx bx-send" />
                  <span>Invoice Paid</span>
                </HOC>
              ) : (
                <div className="loading-white-bg">
                  <Loading />
                </div>
              )}
            </button>
          </div>
          <div className="col-12">
            <ReactToPrint
              trigger={() => (
                <button
                  type="button"
                  // className="btn inverse-btn w-100 mt-3"
                  className="btn secondary-btn icon-btn w-100 mt-3"
                >
                  <i className="bx bx-printer" />
                  <span>Print</span>
                </button>
              )}
              content={() => componentRef.current}
              bodyClass="print-body"
            />
          </div>
        </div>
        <div id="printInvoiceWrapper">
          <InvoiceDetails ref={componentRef} data={data} />
        </div>
      </Card.Body>
    </WrapperCard>
  );
};

export default InvoiceActions;
