import React, { Component } from 'react';
import { connect } from 'react-redux';
import cogoToast from 'cogo-toast';
import moment from 'moment';
import Swal from 'sweetalert2';
import {
  getRequest,
  putRequest,
  patchRequest,
} from '../../../helpers/apiHandlers';
import { historyPush } from '../../../routes/historyPush';

/* Components */
import InvoiceEditing from '../../../components/AdminTools/Invoice/InvoiceEditing';
import InvoiceActions from '../../../components/AdminTools/Invoice/InvoiceActions';

/* custom */
const swalCustomConfirmationButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn main-btn ml-3',
    cancelButton: 'btn inverse-btn',
    title: 'invoice-title',
  },
  buttonsStyling: false,
});

class EditInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceNo: '',
      issueDate: null,
      dueDate: null,
      invoiceTitle: '',
      billFromCompany: '',
      billFromAddress: '',
      billFromEmail: '',
      billFromPhoneNumber: '',
      billToCompany: '',
      billToAddress: '',
      billToEmail: '',
      billToPhoneNumber: '',
      item: '',
      cost: '',
      quantity: '',
      price: '',
      description: '',
      discount: 0,
      discountAmount: 0,
      tax: 0,
      taxAmount: 0,
      footer: '',
      subtotal: 0,
      total: 0,
      paidAt: null,
      sentAt: null,

      /* tempStates */
      tempIssueDate: null,
      tempDueDate: null,
      tempInvoiceTitle: '',
      tempBillToCompany: '',
      tempBillToAddress: '',
      tempBillToEmail: '',
      tempBillToPhoneNumber: '',
      tempCost: 0,
      tempPrice: 0,
      tempQuantity: 0,
      tempDescription: '',
      tempDiscount: 0,
      _tempDiscount: 0,
      tempDiscountAmount: 0,
      tempTax: 0,
      _tempTax: 0,
      tempTaxAmount: 0,
      tempFooter: '',
      tempSubtotal: 0,
      tempTotal: 0,

      /* error handling */
      tempIssueDateError: false,
      tempDueDateError: false,
      tempInvoiceTitleError: false,
      tempBillToCompanyError: false,
      tempBillToAddressError: false,
      tempBillToEmailError: false,
      tempBillToPhoneNumberError: false,
      tempCostError: false,
      tempQuantityError: false,
      tempPriceError: false,
      tempDescriptionError: false,
      _tempDiscountError: false,
      _tempTaxError: false,
      tempFooterError: false,

      /* flags */
      isLoading: false,
      updateLoading: false,
      paidLoading: false,
      sendLoading: false,
      showItem: false,
      from: 'edit',
    };

    this.getInvoiceData = this.getInvoiceData.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleIssueDate = this.handleIssueDate.bind(this);
    this.handleDueDate = this.handleDueDate.bind(this);
    this.showItemDropdown = this.showItemDropdown.bind(this);
    this.hideItemDropdown = this.hideItemDropdown.bind(this);
    this.handleSetDiscountTax = this.handleSetDiscountTax.bind(this);
    this.handleUpdateInvoice = this.handleUpdateInvoice.bind(this);
    this.handleSendInvoice = this.handleSendInvoice.bind(this);
    this.hitSendInvoice = this.hitSendInvoice.bind(this);
    this.handleSetTotal = this.handleSetTotal.bind(this);
    this.handlePaidInvoice = this.handlePaidInvoice.bind(this);
  }

  componentDidMount() {
    const { params } = this.props.match;

    if (params.id) {
      this.getInvoiceData(params.id);
    }
  }

  getInvoiceData(id) {
    this.setState({ isLoading: true });

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    };

    getRequest(`/admin/invoices/${id}`, data, (res) => {
      // console.log(res);
      if (res.success) {
        this.setState({
          invoiceNo: res.data.invNumber,
          issueDate: new Date(res.data.dateIssue),
          dueDate: new Date(res.data.dueDate),
          invoiceTitle: res.data.title,
          billFromCompany: res.data.billFrom.name,
          billFromAddress: res.data.billFrom.address,
          billFromEmail: res.data.billFrom.email,
          billFromPhoneNumber: res.data.billFrom.phone,
          billToCompany: res.data.billTo.name,
          billToAddress: res.data.billTo.address,
          billToEmail: res.data.billTo.email,
          billToPhoneNumber: res.data.billTo.phone,
          item: res.data.item,
          cost: res.data.cost,
          quantity: res.data.quantity,
          price: res.data.price,
          description: res.data.desc,
          discount: res.data.discount,
          discountAmount: res.data.discountAmount,
          tax: res.data.tax,
          taxAmount: res.data.taxAmount,
          footer: res.data.footer,
          subtotal: res.data.subtotal,
          total: res.data.total,
          paidAt: res.data.paidAt,
          sentAt: res.data.sentAt,

          /* tempStates */
          tempIssueDate: new Date(res.data.dateIssue),
          tempDueDate: new Date(res.data.dueDate),
          tempInvoiceTitle: res.data.title,
          tempBillToCompany: res.data.billTo.name,
          tempBillToAddress: res.data.billTo.address,
          tempBillToEmail: res.data.billTo.email,
          tempBillToPhoneNumber: res.data.billTo.phone,
          tempCost: res.data.cost,
          tempQuantity: res.data.quantity,
          tempPrice: res.data.price,
          tempDescription: res.data.desc,
          tempDiscount: res.data.discount,
          tempTax: res.data.tax,
          _tempDiscount: res.data.discount * 100,
          _tempTax: res.data.tax * 100,
          tempDiscountAmount: res.data.discountAmount,
          tempTaxAmount: res.data.taxAmount,
          tempFooter: res.data.footer,
          tempSubtotal: res.data.subtotal,
          tempTotal: res.data.total,

          /* flags */
          isLoading: false,
        });
      } else {
        // console.log(res);
        this.setState({ isLoading: false });
        cogoToast.error(res.message, { position: 'top-right' });
        historyPush('/dashboard/admin-tools/invoice');
      }
    });
  }

  handleUserInput(e) {
    const { id, value } = e.target;

    this.setState({ [id]: value }, () => {
      const stateName = id + 'Error';
      if (this.state[stateName] === true) {
        this.setState({ [stateName]: false });
      }
    });
  }

  handleIssueDate(e) {
    if (e !== null) {
      this.setState({
        tempIssueDate: new Date(e),
      });
    } else {
      this.setState({
        tempIssueDate: new Date(),
      });
    }
  }

  handleDueDate(e) {
    if (e !== null) {
      this.setState({
        tempDueDate: new Date(e),
      });
    } else {
      this.setState({
        tempDueDate: new Date(),
      });
    }
  }

  showItemDropdown() {
    this.setState({
      showItem: true,
    });
  }

  hideItemDropdown() {
    this.setState({
      showItem: false,
    });
  }

  handleSetDiscountTax() {
    const { _tempDiscount, _tempTax } = this.state;

    if (
      _tempDiscount.toString().trim() !== '' &&
      _tempTax.toString().trim() !== ''
    ) {
      this.setState({
        tempDiscount: _tempDiscount / 100,
        tempTax: _tempTax / 100,
        showItem: false,
      });
    } else {
      this.validateFields({ fieldName: '_tempDiscount', value: _tempDiscount });
      this.validateFields({ fieldName: '_tempTax', value: _tempTax });
    }
  }

  validateFields(obj) {
    const { fieldName, value } = obj;

    if (fieldName === 'tempIssueDate' || fieldName === 'tempDueDate') {
      if (value === null) {
        this.setState({
          [obj.fieldName + 'Error']: true,
        });
      }
    } else {
      if (value.toString().trim() === '') {
        this.setState({
          [obj.fieldName + 'Error']: true,
        });
      }
    }
  }

  handleUpdateInvoice() {
    this.setState({ updateLoading: true });

    const {
      tempIssueDate,
      tempDueDate,
      tempInvoiceTitle,
      tempBillToCompany,
      tempBillToAddress,
      tempBillToEmail,
      tempBillToPhoneNumber,
      item,
      tempCost,
      tempQuantity,
      tempPrice,
      tempDescription,
      tempDiscount,
      tempTax,
      tempDiscountAmount,
      tempTaxAmount,
      tempFooter,
      tempSubtotal,
      tempTotal,
    } = this.state;

    if (
      tempIssueDate !== null &&
      tempDueDate !== null &&
      tempInvoiceTitle.trim() !== '' &&
      tempBillToCompany.trim() !== '' &&
      tempBillToAddress.trim() !== '' &&
      tempBillToEmail.trim() !== '' &&
      tempBillToPhoneNumber.toString().trim() !== '' &&
      tempCost.toString().trim() !== '' &&
      tempQuantity.toString().trim() !== '' &&
      tempDescription.trim() !== '' &&
      tempFooter.trim() !== ''
    ) {
      const data = {
        issueDate: moment(tempIssueDate).toISOString(),
        dueDate: moment(tempDueDate).toISOString(),
        title: tempInvoiceTitle,
        billTo: {
          name: tempBillToCompany,
          address: tempBillToAddress,
          email: tempBillToEmail,
          phone: tempBillToPhoneNumber,
        },
        item: item,
        cost: parseFloat(tempCost),
        quantity: parseFloat(tempQuantity),
        price: parseFloat(tempPrice),
        desc: tempDescription,
        discount: parseFloat(tempDiscount),
        discountAmount: parseFloat(tempDiscountAmount),
        tax: parseFloat(tempTax),
        taxAmount: parseFloat(tempTaxAmount),
        footer: tempFooter,
        subtotal: parseFloat(tempSubtotal),
        total: parseFloat(tempTotal),
      };

      // console.log('all clear', data, invoiceNo);

      putRequest(
        `/admin/invoices/${this.props.match.params.id}`,
        data,
        this.props.auth.token,
        (res) => {
          if (res.success) {
            this.setState({
              invoiceNo: res.data.invNumber,
              issueDate: new Date(res.data.dateIssue),
              dueDate: new Date(res.data.dueDate),
              invoiceTitle: res.data.title,
              billToCompany: res.data.billTo.name,
              billToAddress: res.data.billTo.address,
              billToEmail: res.data.billTo.email,
              billToPhoneNumber: res.data.billTo.phone,
              item: res.data.item,
              cost: res.data.cost,
              quantity: res.data.quantity,
              price: res.data.price,
              description: res.data.desc,
              discount: res.data.discount,
              discountAmount: res.data.discountAmount,
              tax: res.data.tax,
              taxAmount: res.data.taxAmount,
              footer: res.data.footer,
              subtotal: res.data.subtotal,
              total: res.data.total,
              paidAt: res.data.paidAt,
              sentAt: res.data.sentAt,
              updateLoading: false,
            });
            cogoToast.success(res.message, { position: 'top-right' });
          } else {
            this.setState({ updateLoading: false });
            cogoToast.error(res.message, { position: 'top-right' });
          }
        }
      );
    } else {
      this.validateFields({ fieldName: 'tempIssueDate', value: tempIssueDate });
      this.validateFields({ fieldName: 'tempDueDate', value: tempDueDate });
      this.validateFields({
        fieldName: 'tempInvoiceTitle',
        value: tempInvoiceTitle,
      });
      this.validateFields({
        fieldName: 'tempBillToCompany',
        value: tempBillToCompany,
      });
      this.validateFields({
        fieldName: 'tempBillToAddress',
        value: tempBillToAddress,
      });
      this.validateFields({
        fieldName: 'tempBillToEmail',
        value: tempBillToEmail,
      });
      this.validateFields({
        fieldName: 'tempBillToPhoneNumber',
        value: tempBillToPhoneNumber,
      });
      this.validateFields({ fieldName: 'tempCost', value: tempCost });
      this.validateFields({ fieldName: 'tempQuantity', value: tempQuantity });
      this.validateFields({
        fieldName: 'tempDescription',
        value: tempDescription,
      });
      this.validateFields({ fieldName: 'tempFooter', value: tempFooter });

      cogoToast.error('Cannot leave empty field', { position: 'top-right' });
      this.setState({ updateLoading: false });
    }
  }

  handleSendInvoice() {
    this.setState({ sendLoading: true });

    const {
      tempIssueDate,
      tempDueDate,
      tempInvoiceTitle,
      tempBillToCompany,
      tempBillToAddress,
      tempBillToEmail,
      tempBillToPhoneNumber,
      tempCost,
      tempQuantity,
      tempDescription,
      tempDiscount,
      tempTax,
      tempFooter,
      issueDate,
      dueDate,
      invoiceTitle,
      billToCompany,
      billToAddress,
      billToEmail,
      billToPhoneNumber,
      cost,
      quantity,
      description,
      discount,
      tax,
      footer,
      sentAt,
    } = this.state;

    if (sentAt === null) {
      if (
        moment(tempIssueDate).format() === moment(issueDate).format() &&
        moment(tempDueDate).format() === moment(dueDate).format() &&
        tempInvoiceTitle === invoiceTitle &&
        tempBillToCompany === billToCompany &&
        tempBillToAddress === billToAddress &&
        tempBillToEmail === billToEmail &&
        tempBillToPhoneNumber === billToPhoneNumber &&
        parseFloat(tempCost) === cost &&
        tempQuantity === quantity &&
        tempDescription === description &&
        parseFloat(tempDiscount) === discount &&
        parseFloat(tempTax) === tax &&
        tempFooter === footer
      ) {
        this.hitSendInvoice();
      } else {
        swalCustomConfirmationButtons
          .fire({
            title: 'Are you sure want to send without updating your changes?',
            text: 'Click "Update Invoice" to save your changes.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, proceed',
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.hitSendInvoice();
            }
            this.setState({ sendLoading: false });
          });
      }
    } else {
      swalCustomConfirmationButtons
        .fire({
          title: `This invoice has already been sent on ${moment(sentAt).format(
            'DD MMMM YYYY, h:mm:ss a'
          )}`,
          text: 'Are you sure you want to resend it?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Yes, proceed',
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (
              moment(tempIssueDate).format() === moment(issueDate).format() &&
              moment(tempDueDate).format() === moment(dueDate).format() &&
              tempInvoiceTitle === invoiceTitle &&
              tempBillToCompany === billToCompany &&
              tempBillToAddress === billToAddress &&
              tempBillToEmail === billToEmail &&
              tempBillToPhoneNumber === billToPhoneNumber &&
              parseFloat(tempCost) === cost &&
              tempQuantity === quantity &&
              tempDescription === description &&
              parseFloat(tempDiscount) === discount &&
              parseFloat(tempTax) === tax &&
              tempFooter === footer
            ) {
              this.hitSendInvoice();
            } else {
              swalCustomConfirmationButtons
                .fire({
                  title:
                    'Are you sure want to send without updating your changes?',
                  text: 'Click "Update Invoice" to save your changes.',
                  icon: 'warning',
                  showCancelButton: true,
                  cancelButtonText: 'Cancel',
                  confirmButtonText: 'Yes, proceed',
                  reverseButtons: true,
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    this.hitSendInvoice();
                  }
                  this.setState({ sendLoading: false });
                });
            }
          }
          this.setState({ sendLoading: false });
        });
    }
  }

  hitSendInvoice() {
    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    };

    getRequest(
      `/admin/invoices/send/${this.props.match.params.id}`,
      data,
      (res) => {
        this.setState({ sendLoading: false });
        if (res.success) {
          cogoToast.success(res.message, { position: 'top-right' });
        } else {
          cogoToast.error(res.message, { position: 'top-right' });
        }
      }
    );
  }

  handleSetTotal(e) {
    const { price, subTotal, discountAmount, taxAmount, invoiceTotal } = e;
    const { tempSubtotal, tempTotal } = this.state;

    if (tempSubtotal !== subTotal || tempTotal !== invoiceTotal) {
      this.setState({
        tempPrice: price,
        tempSubtotal: subTotal,
        tempDiscountAmount: discountAmount,
        tempTaxAmount: taxAmount,
        tempTotal: invoiceTotal,
      });
    }
  }

  handlePaidInvoice() {
    this.setState({ paidLoading: true });
    patchRequest(
      `/admin/invoices/paid/${this.props.match.params.id}`,
      {},
      this.props.auth.token,
      (res) => {
        this.setState({ paidLoading: false });
        if (res.success) {
          cogoToast.success(res.message, { position: 'top-right' });
        } else {
          this.setState({ paidLoading: false });
          cogoToast.error(res.message, { position: 'top-right' });
        }
      }
    );
  }

  render() {
    const {
      handleUserInput,
      handleIssueDate,
      handleDueDate,
      showItemDropdown,
      hideItemDropdown,
      handleSetDiscountTax,
      handleUpdateInvoice,
      handleSendInvoice,
      handleSetTotal,
      handlePaidInvoice,
    } = this;

    return (
      <div className="EditInvoice">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-9">
                <InvoiceEditing
                  data={this.state}
                  handlers={{
                    handleUserInput,
                    handleIssueDate,
                    handleDueDate,
                    showItemDropdown,
                    hideItemDropdown,
                    handleSetDiscountTax,
                    handleUpdateInvoice,
                    handleSetTotal,
                    handlePaidInvoice,
                  }}
                />
              </div>
              <div className="col-12 col-md-3">
                <InvoiceActions
                  data={this.state}
                  handlers={{ handleSendInvoice, handlePaidInvoice }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(EditInvoice);
