import React, { Component } from 'react';
import { connect } from 'react-redux';
import Lottie from 'lottie-react';
import MicrosoftLogin from 'react-microsoft-login';
import { Form } from 'react-bootstrap';
import cogoToast from 'cogo-toast';
import { historyPush } from '../../routes/historyPush';
import { postRequestWithoutAccessToken } from '../../helpers/apiHandlers';
import {
  loginWithMicrosoftSSO,
  setLoadingStatus,
  setLoginSuccess,
  setStaySignIn,
  setMSAL,
  onLogout,
} from '../../store/actions/authAction';
import ForgotPasswordModal from '../../components/Authenticate/ForgotPasswordModal';
import VeficiationModal from '../../components/Authenticate/VerificationModal';
import Loading from '../../components/shared/Loading/Loading';
import HOC from '../../components/shared/HOC';
import config from '../../config/constant';

const msClientId = config.msClientId;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      accessCode: '',
      staySignIn: false,
      forgotPasswordEmail: '',
      showForgotPassword: false,
      showVerificationModal: false,
      verificationTitle: '',
      verificationMessage: '',
      isLoading: false,
      status: null,
      codeFlag: false,
      msLoginFlag: false,

      /* Error Handling */
      emailError: false,
      passwordError: false,
      forgotPasswordEmailError: false,
    };

    this.handleVerificationFlow = this.handleVerificationFlow.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleEmailLogin = this.handleEmailLogin.bind(this);
    this.handleEmailAndPassword = this.handleEmailAndPassword.bind(this);
    this.handleEmailAndCode = this.handleEmailAndCode.bind(this);
    this.handleMSLogin = this.handleMSLogin.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseVerificationModal =
      this.handleCloseVerificationModal.bind(this);
    this.handleCancelLogin = this.handleCancelLogin.bind(this);
  }

  componentDidMount() {
    if (this.props.location.search === '') {
      if (this.props.auth.isLoggedIn) {
        historyPush('/dashboard');
      } else {
        this.props.setLoadingStatus(false);
      }
    } else {
      if (this.props.auth.isLoggedIn) {
        this.props.onLogout();
      }

      this.handleVerificationFlow();
    }
  }

  componentWillUnmount() {
    this.props.setLoadingStatus(false);
  }

  handleVerificationFlow() {
    const actionParam = new URLSearchParams(this.props.location.search).get(
      'action'
    );

    const statusParam = new URLSearchParams(this.props.location.search).get(
      'status'
    );

    if (actionParam === 'verify') {
      if (statusParam === 'success') {
        this.setState({
          showVerificationModal: true,
          verificationTitle: 'Account verification successful',
          verificationMessage:
            'Your login credentials have been sent to your registered email address.',
        });
      } else if (statusParam === 'token-error') {
        this.setState({
          showVerificationModal: true,
          verificationTitle: 'Account verification failed',
          verificationMessage:
            'Something went wrong. Please try again later or contact your admin.',
        });
      } else if (statusParam === 'exist') {
        this.setState({
          showVerificationModal: true,
          verificationTitle: 'Account verification success',
          verificationMessage:
            'Your account is already verified. Your login credentials have been sent to your registered email address.',
        });
      } else if (statusParam === 'technical-exist') {
        this.setState({
          showVerificationModal: true,
          verificationTitle: 'Account verification failed',
          verificationMessage:
            'An existing technical user has been assigned. Please contact your admin for a new verification link without a technical role.',
        });
      } else {
        historyPush('/dashboard');
      }
    } else if (actionParam === 'password') {
      if (statusParam === 'success') {
        this.setState({
          showVerificationModal: true,
          verificationTitle: 'Reset password successful',
          verificationMessage:
            'Your new password has been sent to your registered email address.',
        });
      } else if (statusParam === 'error') {
        this.setState({
          showVerificationModal: true,
          verificationTitle: 'Reset password failed',
          verificationMessage:
            'Something went wrong. Please try again later or contact your admin.',
        });
      } else if (statusParam === 'expired') {
        this.setState({
          showVerificationModal: true,
          verificationTitle: 'Reset password failed',
          verificationMessage:
            'Reset password link has expired. Please contact your admin.',
        });
      } else {
        historyPush('/dashboard');
      }
    }
  }

  handleUserInput(e) {
    const id = e.target.id;
    const { value, checked } = e.target;

    if (id === 'email') {
      this.setState({ email: value });

      if (this.state.emailError) {
        this.setState({ emailError: false });
      }
    } else if (id === 'password') {
      this.setState({ password: value });

      if (this.state.passwordError) {
        this.setState({ passwordError: false });
      }
    } else if (id === 'staySignIn') {
      this.setState({ staySignIn: checked });
    } else if (id === 'accessCode') {
      this.setState({ accessCode: value });

      if (this.state.accessCodeError) {
        this.setState({ accessCodeError: false });
      }
    } else if (id === 'forgotPasswordEmail') {
      this.setState({ forgotPasswordEmail: value });

      if (this.state.forgotPasswordEmailError) {
        this.setState({ forgotPasswordEmailError: false });
      }
    }
  }

  checkVal(obj) {
    if (obj.value.trim() === '') {
      this.setState({
        [obj.fieldName + 'Error']: true,
      });
    }
  }

  handleEmailLogin(e) {
    e.preventDefault();
    const { email } = this.state;

    if (email.trim() !== '') {
      this.setState({ isLoading: true });

      const data = {
        email: this.state.email,
      };

      postRequestWithoutAccessToken('/login/email', data, (res) => {
        if (res.success) {
          this.setState({ status: res.data.status, isLoading: false }, () => {
            let msg = 'Please proceed with Microsoft Single Sign On';

            if (this.state.status === 'external')
              msg = 'Email found, please enter your password';

            cogoToast.success(msg, { position: 'top-right' });
          });
        } else {
          this.setState({ isLoading: false }, () =>
            cogoToast.error(res.message, { position: 'top-right' })
          );
        }
      });
    } else {
      this.checkVal({ fieldName: 'email', value: email });
    }
  }

  handleEmailAndPassword(e) {
    e.preventDefault();
    const { email, password } = this.state;

    if (email.trim() !== '' && password.trim() !== '') {
      this.setState({ isLoading: true });

      const data = {
        email: email,
        password: password,
      };

      postRequestWithoutAccessToken('/login/password', data, (res) => {
        if (res.success) {
          this.setState({ codeFlag: true, isLoading: false }, () =>
            cogoToast.success(res.message, { position: 'top-right' })
          );
        } else {
          this.setState({ isLoading: false }, () =>
            cogoToast.error(res.message, { position: 'top-right' })
          );
        }
      });
    } else {
      this.checkVal({ fieldName: 'email', value: email });
      this.checkVal({ fieldName: 'password', value: password });
    }
  }

  handleEmailAndCode(e) {
    e.preventDefault();
    const { email, accessCode, staySignIn } = this.state;

    if (accessCode.trim() !== '') {
      this.setState({ isLoading: true });
      const data = {
        email: email,
        code: accessCode,
        staySignIn: staySignIn ? 1 : 0,
      };

      let resMsg = 'Login Successfully';

      postRequestWithoutAccessToken('/login/code', data, (res) => {
        this.setState({ isLoading: false });

        if (res.success) {
          this.props.setLoginSuccess(res.data);
          this.props.setStaySignIn(data.staySignIn);

          if (res.code !== 200) {
            resMsg = res.message;
          }

          cogoToast.success(resMsg, { position: 'top-right' });
          historyPush('/dashboard');
        } else {
          if (res.message !== 'Account Suspended') {
            cogoToast.error(res.message, { position: 'top-right' });
            if (res.code === 401 && res.message === 'Invalid Code') {
              this.handleCancelLogin();
            }
          }
        }
      });
    } else {
      this.checkVal({ fieldName: 'accessCode', value: accessCode });
    }
  }

  handleMSLogin(err, data, msal) {
    const { msLoginFlag, staySignIn } = this.state;

    if (!msLoginFlag) {
      if (data) {
        const loginData = {
          platform: 'microsoft',
          token: data.accessToken,
          staySignIn: staySignIn ? 1 : 0,
        };

        this.setState({ msLoginFlag: true }, () => {
          this.props.loginWithMicrosoftSSO(loginData);
          this.props.setMSAL(msal);
        });
      } else {
        cogoToast.error(err.message, { position: 'top-right' });
      }
    }
  }

  handleForgotPassword(e) {
    e.preventDefault();
    const { forgotPasswordEmail } = this.state;

    if (forgotPasswordEmail.trim() !== '') {
      this.setState({ isLoading: true });

      const data = {
        email: forgotPasswordEmail,
      };

      postRequestWithoutAccessToken('/forgot-password', data, (res) => {
        this.setState({ isLoading: false });

        if (res.success) {
          cogoToast.success(res.message, { position: 'top-right' });
        } else {
          cogoToast.error(res.message, { position: 'top-right' });
        }
      });
    } else {
      this.checkVal({
        fieldName: 'forgotPasswordEmail',
        value: forgotPasswordEmail,
      });
    }
  }

  handleCloseModal() {
    this.setState({
      showForgotPassword: false,
      forgotPasswordEmail: '',
      forgotPasswordEmailError: false,
    });
  }

  handleCloseVerificationModal() {
    this.setState({ showVerificationModal: false });
  }

  handleCancelLogin() {
    this.setState({
      email: '',
      password: '',
      accessCode: '',
      staySignIn: false,
      forgotPasswordEmail: '',
      showForgotPassword: false,
      showVerificationModal: false,
      verificationTitle: '',
      verificationMessage: '',
      isLoading: false,
      status: null,
      codeFlag: false,
      msLoginFlag: false,
      emailError: false,
      passwordError: false,
      forgotPasswordEmailError: false,
    });
  }

  render() {
    const {
      email,
      password,
      accessCode,
      emailError,
      passwordError,
      accessCodeError,
      isLoading,
      status,
      codeFlag,
    } = this.state;

    return (
      <div className="Login">
        <div className="login-wrapper">
          <div className="row mx-0 px-0 bg-white">
            <div className="col-lg-6 leftContainer d-flex flex-column">
              <div className="formContainer container">
                <div className="row text-center">
                  <div className="col-12">
                    <div className="logo-container">
                      <img
                        src={require('../../assets/auth/tt-5g.png').default}
                        alt="login-logo"
                        className="login-logo"
                      />
                      <img
                        src={
                          require('../../assets/auth/tt-gobeyond.png').default
                        }
                        alt="go-beyond"
                        className="go-beyond"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <h4>Tune Talk Enterprise Messaging</h4>
                  </div>
                </div>
                <div className="col-12 pt-3">
                  <div className="row">
                    <div className="col-12">
                      <div className="text-divider">
                        <small>
                          {!codeFlag
                            ? 'login with registered email'
                            : 'Enter Security Code'}
                        </small>
                      </div>
                    </div>
                    <div className="col-12">
                      {status !== 'internal' && (
                        <Form>
                          {!codeFlag && (
                            <div className="row">
                              <div className="col-12">
                                <Form.Group controlId="email">
                                  <Form.Label>Email address</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter your email"
                                    value={email}
                                    className={emailError ? 'is-invalid' : ''}
                                    onChange={(e) => this.handleUserInput(e)}
                                  />
                                  {emailError && (
                                    <Form.Text>Email is required</Form.Text>
                                  )}
                                </Form.Group>
                              </div>
                            </div>
                          )}
                          {status === 'external' && (
                            <HOC>
                              {!codeFlag && (
                                <div className="row">
                                  <div className="col-12">
                                    <Form.Group controlId="password">
                                      <Form.Label>Password</Form.Label>
                                      <Form.Control
                                        type="password"
                                        placeholder="Enter your password"
                                        value={password}
                                        className={
                                          passwordError ? 'is-invalid' : ''
                                        }
                                        onChange={(e) =>
                                          this.handleUserInput(e)
                                        }
                                      />
                                      {passwordError && (
                                        <Form.Text>
                                          Password is required
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </div>
                                </div>
                              )}
                              {codeFlag && (
                                <div className="row">
                                  <div className="col-12">
                                    <Form.Group controlId="accessCode">
                                      <div className="accessCode">
                                        <Form.Label>
                                          (We have just sent it to your email)
                                        </Form.Label>
                                        <br />
                                        <span>
                                          **The security code will only be valid
                                          for 3 minutes and for 1 attempt**
                                        </span>
                                      </div>
                                      <Form.Label>Login Access Code</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter login access code"
                                        value={accessCode}
                                        className={
                                          accessCodeError ? 'is-invalid' : ''
                                        }
                                        onChange={(e) =>
                                          this.handleUserInput(e)
                                        }
                                      />
                                      {accessCodeError && (
                                        <Form.Text>
                                          Login access code is required
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </div>
                                </div>
                              )}
                              <div className="row">
                                <div className="col-6">
                                  {codeFlag && (
                                    <Form.Group controlId="staySignIn">
                                      <Form.Check
                                        id="staySignIn"
                                        className="m-0"
                                        type="checkbox"
                                        label="Keep me logged in"
                                        onChange={(e) =>
                                          this.handleUserInput(e)
                                        }
                                      />
                                    </Form.Group>
                                  )}
                                </div>
                                {!codeFlag && (
                                  <div className="col-6 text-right">
                                    <Form.Group controlId="staySignIn">
                                      <button
                                        className="forgot-password"
                                        type="button"
                                        onClick={() =>
                                          this.setState({
                                            showForgotPassword: true,
                                          })
                                        }
                                      >
                                        Forgot Password?
                                      </button>
                                    </Form.Group>
                                  </div>
                                )}
                              </div>
                            </HOC>
                          )}
                          <div className="row">
                            {codeFlag && (
                              <div className="col-6">
                                <button
                                  className="btn inverse-btn w-100"
                                  type="button"
                                  onClick={() => this.handleCancelLogin()}
                                >
                                  <span>Cancel</span>
                                </button>
                              </div>
                            )}
                            <div className={codeFlag ? 'col-6' : 'col-12'}>
                              <button
                                className="btn main-btn w-100"
                                type="submit"
                                disabled={
                                  isLoading || this.props.auth.isLoading
                                }
                                onClick={
                                  status === null
                                    ? (e) => this.handleEmailLogin(e)
                                    : !codeFlag
                                    ? (e) => this.handleEmailAndPassword(e)
                                    : (e) => this.handleEmailAndCode(e)
                                }
                              >
                                {!isLoading ? (
                                  <span>
                                    {status === null
                                      ? 'Next'
                                      : !codeFlag
                                      ? 'Next'
                                      : 'Login'}
                                  </span>
                                ) : (
                                  <Loading />
                                )}
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                      {status === 'internal' && (
                        <Form>
                          <div className="row">
                            <div className="col-12">
                              <div
                                className="msalbutton"
                                aria-disabled={isLoading}
                                disabled={isLoading}
                              >
                                <MicrosoftLogin
                                  clientId={msClientId}
                                  authCallback={this.handleMSLogin}
                                />
                                {isLoading && <Loading />}
                              </div>
                            </div>
                            <div className="col-6">
                              <Form.Group controlId="staySignIn">
                                <Form.Check
                                  id="staySignIn"
                                  className="m-0"
                                  type="checkbox"
                                  label="Keep me logged in"
                                  onChange={(e) => this.handleUserInput(e)}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-12">
                              <button
                                className="btn inverse-btn w-100"
                                type="button"
                                onClick={() => this.handleCancelLogin()}
                              >
                                <span>Cancel</span>
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 rightContainer">
              <Lottie
                animationData={require('../../assets/auth/login-bg.json')}
              />
            </div>
          </div>
        </div>
        <ForgotPasswordModal
          showForgotPassword={this.state.showForgotPassword}
          handlers={{
            handleCloseModal: this.handleCloseModal,
            handleUserInput: this.handleUserInput,
            handleForgotPassword: this.handleForgotPassword,
          }}
          data={this.state}
        />
        <VeficiationModal
          showVerificationModal={this.state.showVerificationModal}
          handleCloseModal={this.handleCloseVerificationModal}
          verificationTitle={this.state.verificationTitle}
          verificationMessage={this.state.verificationMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginWithMicrosoftSSO: (loginData) =>
    dispatch(loginWithMicrosoftSSO(loginData)),
  setLoadingStatus: (isLoading) => dispatch(setLoadingStatus(isLoading)),
  setLoginSuccess: (lsd) => dispatch(setLoginSuccess(lsd)),
  setStaySignIn: (ssi) => dispatch(setStaySignIn(ssi)),
  setMSAL: (msal) => dispatch(setMSAL(msal)),
  onLogout: () => dispatch(onLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
