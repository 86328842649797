import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';
import { Card, Form } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { getRequest } from '../../helpers/apiHandlers';
import Loading from '../../components/shared/Loading/Loading';
import WrapperCard from '../../components/shared/Cards/WrapperCard';
import AdminActivityTable from '../../components/AdminTools/AdminActivityTable';

class AdminActivityLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      dateValue: '',
      adminList: [],
      adminLogs: [],
      selectedAdmin: [],
      isLoading: false,
      currentPage: 1,
      perPage: 10,
      totalData: 0,
    };

    this.getAdmins = this.getAdmins.bind(this);
    this.handleShowActivityLogs = this.handleShowActivityLogs.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.handleSelectAdmin = this.handleSelectAdmin.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.getAdmins();
  }

  dataModeling(data) {
    if (data.length > 0) {
      let tempData = [];

      data.map((item) =>
        tempData.push({
          ...item,
          label: item.username,
          value: item.id,
        })
      );

      return tempData;
    }
  }

  getAdmins() {
    this.setState({ isLoading: true });

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    };

    getRequest('/admin/admins', data, (res) => {
      if (res.success) {
        const adminList =
          res.data.length > 0 ? this.dataModeling(res.data) : [];
        this.setState(
          {
            adminList: adminList,
            isLoading: false,
          },
          () => this.handleShowActivityLogs()
        );
      } else {
        // console.log(res);
        this.setState({ isLoading: false });
      }
    });
  }

  handleShowActivityLogs(showData) {
    this.setState({ isLoading: true });

    const adminArray = [];
    let adminString = '';

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
      params: {
        page: showData ? 1 : this.state.currentPage,
        perPage: this.state.perPage,
      },
    };

    if (this.state.startDate !== null && this.state.endDate !== null) {
      data.params.startDate = moment(this.state.startDate).toISOString();
      data.params.endDate = moment(this.state.endDate).toISOString();
    }

    if (this.state.selectedAdmin.length > 0) {
      this.state.selectedAdmin.map((admin) =>
        adminArray.push(admin.id.toString())
      );
    }

    adminString = adminArray.join(',');

    if (adminString.trim() !== '') {
      data.params.adminId = adminString;
    }

    getRequest('/admin/logs', data, (res) => {
      if (res.success) {
        const adminLogs =
          res.data.logs.length > 0 ? this.dataModeling(res.data.logs) : [];
        this.setState({
          adminLogs: adminLogs,
          currentPage: res.data.pagination.currentPage,
          perPage: res.data.pagination.perPage,
          totalData: res.data.pagination.totalData,
          isLoading: false,
        });
      } else {
        // console.log(res);
        this.setState({ isLoading: false });
      }
    });
  }

  handleDatePicker(e, p) {
    if (e.type === 'apply') {
      const dateValue = p.element.val(
        p.startDate.format('DD/MM/YYYY hh:mm A') +
          ' - ' +
          p.endDate.format('DD/MM/YYYY hh:mm A')
      );

      this.setState({
        dateValue: dateValue,
        startDate: p.startDate,
        endDate: p.endDate,
      });
    }

    if (e.type === 'cancel') {
      const dateValue = p.element.val('');

      this.setState({
        dateValue: dateValue,
        startDate: null,
        endDate: null,
      });
    }
  }

  handleSelectAdmin(e) {
    this.setState({ selectedAdmin: e });
  }

  handlePageChange(pageNo) {
    this.setState(
      {
        currentPage: pageNo,
      },
      () => {
        this.handleShowActivityLogs();
      }
    );
  }

  render() {
    return (
      <div className="AdminActivityLog">
        <div className="row">
          <div className="col-12">
            <WrapperCard
              header={{
                exist: true,
                data: {
                  iconName: 'bx bx-receipt',
                  title: 'ADMIN - Activity Log',
                },
              }}
            >
              <Card.Body>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Form.Group controlId="dateRange">
                      <Form.Label>Date & Time Range</Form.Label>
                      <DateRangePicker
                        initialSettings={{
                          timePicker: true,
                          locale: {
                            cancelLabel: 'Clear',
                            format: 'DD/MM/YYYY hh:mm A',
                          },
                          autoUpdateInput: false,
                        }}
                        onEvent={(event, picker) =>
                          this.handleDatePicker(event, picker)
                        }
                      >
                        <Form.Control
                          type="text"
                          className="form-control datetime"
                          placeholder="Select Date & Time"
                          aria-invalid="false"
                          autoComplete="off"
                        />
                      </DateRangePicker>
                    </Form.Group>
                  </div>
                  <div className="col-3">
                    <Form.Group
                      controlId="selectAdmin"
                      className="select-admin"
                    >
                      <Select
                        isMulti
                        name="selectAdmin"
                        placeholder="Select Admin"
                        options={this.state.adminList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        styles={{
                          multiValue: (base) => ({
                            ...base,
                            backgroundColor: '#dd0613',
                            color: '#fff',
                          }),
                          multiValueLabel: (base) => ({
                            ...base,
                            color: '#fff',
                          }),
                        }}
                        onChange={(e) => this.handleSelectAdmin(e)}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Card.Body>
              <Card.Body className="pt-0">
                <div className="row">
                  <div className="col-12 d-flex flex-sm-row flex-column justify-content-center">
                    <button
                      type="submit"
                      className="btn main-btn"
                      onClick={() => this.handleShowActivityLogs(true)}
                      disabled={this.state.isLoading}
                    >
                      {this.state.isLoading ? <Loading /> : <span>Apply</span>}
                    </button>
                  </div>
                  <AdminActivityTable
                    data={this.state}
                    handlePageChange={this.handlePageChange}
                  />
                </div>
              </Card.Body>
            </WrapperCard>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AdminActivityLog);
