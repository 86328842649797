import React from 'react';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { historyPush } from '../../../routes/historyPush';
import Loading from '../../shared/Loading/Loading';

const InvoiceTable = ({ data, handlePageChange }) => (
  <div className="col-12 mt-5">
    <Table responsive>
      <thead>
        <tr>
          <th>#INV-ID</th>
          <th>Company Name</th>
          <th>Amount</th>
          <th>Created Date</th>
          <th>Due Date</th>
          <th>Paid Date</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data.isLoading && (
          <tr>
            <td colSpan="7" className="loading-white-bg">
              <Loading />
            </td>
          </tr>
        )}
        {!data.isLoading &&
          data.invoiceData.length > 0 &&
          data.invoiceData.map((invoice) => (
            <tr key={invoice.id}>
              <td>{invoice.id}</td>
              <td>{invoice.billTo.name}</td>
              <td>{`RM ${invoice.total.toLocaleString()}`}</td>
              <td>
                {moment(invoice.createdAt).format('DD MMMM YYYY, h:mm:ss a')}
              </td>
              <td>
                {moment(invoice.dueDate).format('DD MMMM YYYY, h:mm:ss a')}
              </td>
              <td>
                {invoice.paidAt !== null
                  ? moment(invoice.paidAt).format('DD MMMM YYYY, h:mm:ss a')
                  : 'N/A'}
              </td>
              <td>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Show Detail</Tooltip>}
                >
                  <i
                    className="bx bx-search-alt-2"
                    disabled={data.userStatusLoading}
                    onClick={() =>
                      historyPush(
                        `/dashboard/admin-tools/invoice/view-invoice/${invoice.id}`
                      )
                    }
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Edit Invoice</Tooltip>}
                >
                  <i
                    className="bx bxs-edit"
                    disabled={data.userStatusLoading}
                    onClick={() =>
                      historyPush(
                        `/dashboard/admin-tools/invoice/edit-invoice/${invoice.id}`
                      )
                    }
                  />
                </OverlayTrigger>
              </td>
            </tr>
          ))}
        {!data.isLoading && data.invoiceData.length === 0 && (
          <tr className="text-center">
            <td colSpan="7">
              <img
                alt="no-data-found"
                src={require('../../../assets/shared/no-data.png').default}
              />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
    {data.totalData > 10 && (
      <div className="pagination-custom-style">
        <Pagination
          activePage={data.currentPage}
          itemsCountPerPage={data.perPage}
          totalItemsCount={data.totalData}
          onChange={(e) => handlePageChange(e)}
          hideFirstLastPages
          prevPageText="<PREV"
          nextPageText="NEXT>"
          activeClass="active-page"
          activeLinkClass="active-link"
          itemClass=""
          linkClass="defaul-link"
        />
      </div>
    )}
  </div>
);

export default InvoiceTable;
