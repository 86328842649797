import React from 'react';
import { Card, Form } from 'react-bootstrap';
import WrapperCard from '../shared/Cards/WrapperCard';
import HOC from '../shared/HOC';
import Loading from '../shared/Loading/Loading';

const ChangeEmail = (props) => (
  <WrapperCard
    header={{
      exist: true,
      data: { iconName: 'bx bx-envelope', title: 'Change Email' },
    }}
  >
    <Card.Body>
      <div className="row">
        <div className="col-12 mb-3">
          <span>
            <strong>Email Reset</strong>
          </span>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="col-12 px-0">
                <Form.Group controlId="oldEmail">
                  <Form.Label>Old Email</Form.Label>
                  <Form.Control
                    placeholder="Old Email"
                    type="text"
                    className={props.data.oldEmailError ? 'is-invalid' : ''}
                    value={props.data.oldEmail}
                    onChange={(e) => props.handlers.handleUserInput(e)}
                  />
                  {props.data.oldEmailError !== '' && (
                    <Form.Text>{props.data.oldEmailError}</Form.Text>
                  )}
                </Form.Group>
              </div>
              <div className="col-12 px-0">
                <Form.Group controlId="newEmail">
                  <Form.Label>New Email</Form.Label>
                  <Form.Control
                    placeholder="New Email"
                    type="text"
                    className={props.data.newEmailError ? 'is-invalid' : ''}
                    value={props.data.newEmail}
                    onChange={(e) => props.handlers.handleUserInput(e)}
                  />
                  {props.data.newEmailError !== '' && (
                    <Form.Text>{props.data.newEmailError}</Form.Text>
                  )}
                </Form.Group>
              </div>
              <div className="col-12 px-0">
                <Form.Group controlId="confirmNewEmail">
                  <Form.Label>Confirm New Email</Form.Label>
                  <Form.Control
                    placeholder="Confirm New Email"
                    type="text"
                    className={
                      props.data.confirmNewEmailError ? 'is-invalid' : ''
                    }
                    value={props.data.confirmNewEmail}
                    onChange={(e) => props.handlers.handleUserInput(e)}
                    disabled={props.data.newEmail.trim() === ''}
                  />
                  {props.data.confirmNewEmailError !== '' && (
                    <Form.Text>{props.data.confirmNewEmailError}</Form.Text>
                  )}
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex flex-sm-row flex-column justify-content-center">
          <button
            type="submit"
            className="btn main-btn icon-btn"
            onClick={() => props.handlers.handleChangeEmail()}
            disabled={props.data.emailLoading}
          >
            {!props.data.emailLoading ? (
              <HOC>
                <i className="bx bx-save" />
                <span>Reset</span>
              </HOC>
            ) : (
              <Loading />
            )}
          </button>
        </div>
      </div>
    </Card.Body>
  </WrapperCard>
);

export default ChangeEmail;
