import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Card, Table } from 'react-bootstrap';

/* components */
import WrapperCard from '../../shared/Cards/WrapperCard';

/* helpers */
import {
  formatPercentage,
  formatAmount,
} from '../../../helpers/helperFunction';

const InvoiceDetails = React.forwardRef((props, ref) => {
  const { data } = props;

  const [__tempBillFromCompany, set__tempBillFromCompany] = useState('');
  const [__tempBillFromAddress, set__tempBillFromAddress] = useState('');
  const [__tempBillFromEmail, set__tempBillFromEmail] = useState('');
  const [__tempBillFromPhoneNumber, set__tempBillFromPhoneNumber] =
    useState('');

  const [__tempBillToCompany, set__tempBillToCompany] = useState('');
  const [__tempBillToAddress, set__tempBillToAddress] = useState('');
  const [__tempBillToEmail, set__tempBillToEmail] = useState('');
  const [__tempBillToPhoneNumber, set__tempBillToPhoneNumber] = useState('');

  useEffect(() => {
    if (data.from === 'edit') {
      set__tempBillFromCompany(data.billFromCompany);
      set__tempBillFromAddress(data.billFromAddress);
      set__tempBillFromEmail(data.billFromEmail);
      set__tempBillFromPhoneNumber(data.billFromPhoneNumber);

      set__tempBillToCompany(data.billToCompany);
      set__tempBillToAddress(data.billToAddress);
      set__tempBillToEmail(data.billToEmail);
      set__tempBillToPhoneNumber(data.billToPhoneNumber);
    } else {
      set__tempBillFromCompany(data.billFrom.name);
      set__tempBillFromAddress(data.billFrom.address);
      set__tempBillFromEmail(data.billFrom.email);
      set__tempBillFromPhoneNumber(data.billFrom.phone);

      set__tempBillToCompany(data.billTo.name);
      set__tempBillToAddress(data.billTo.address);
      set__tempBillToEmail(data.billTo.email);
      set__tempBillToPhoneNumber(data.billTo.phone);
    }
  }, [data]);

  return (
    <WrapperCard
      header={{
        exist: true,
        data: {
          iconName: 'bx bx-receipt',
          title: 'ADMIN - View Invoice',
        },
      }}
    >
      <div id="printInvoice" ref={ref}>
        <Card.Body>
          <div className="row">
            <div className="col-xl-4 col-md-12">
              <div className="logo-container">
                <img
                  src={require('../../../assets/auth/tt-5g.png').default}
                  alt="login-logo"
                  className="login-logo"
                  width="100"
                />
                <img
                  src={require('../../../assets/auth/tt-gobeyond.png').default}
                  alt="go-beyond"
                  className="go-beyond"
                  width="100"
                />
              </div>
              <span className="invoice-number mr-50 h5">
                <br />
                <br />
                <strong>TUNE TALK SDN BHD</strong>
              </span>
              <span>(720957-V)</span>
              <small>
                <br />
                19-03-01, LEVEL 3, WISMA TUNE (FKA PNB DAMANSARA)
                <br />
                19 JALAN DUNGUN, BUKIT DAMANSARA
                <br />
                50490 KUALA LUMPUR
                <br />
                MALAYSIA
                <br />
                TEL: +603-2782 0000 FAX: +603-2011 5511
                <br />
                SST NO: W10-1808-32000966
                <br />
              </small>
            </div>
            <div className="col-xl-8 col-md-12">
              <div className="d-flex align-items-center justify-content-xl-end flex-wrap">
                <div className="mr-3">
                  <h1 className="invoice-number mr-50 h1">
                    <strong>INVOICE</strong>
                  </h1>
                  <span className="text-muted pr-5">
                    <br />
                    <br />
                    <br />
                    Document Date:
                  </span>
                  <span className="float-right font-weight-bold">
                    {moment(data.issueDate).format('DD MMMM YYYY')}
                  </span>
                  <span className="text-muted pr-5">
                    <br />
                    Document Number:
                  </span>
                  <span className="float-right font-weight-bold">
                    {data.invoiceNo}
                  </span>
                  <span className="text-muted pr-5">
                    <br />
                    Term:
                  </span>
                  <span className="float-right font-weight-bold">COD</span>
                  <span className="text-muted pr-5">
                    <br />
                    Customer Reference:
                  </span>
                  <span className="float-right font-weight-bold">
                    {data.invoiceNo.substring(3)}
                  </span>
                  <span className="text-muted pr-5">
                    <br />
                    Delivery Order Number:
                  </span>
                  <span className="float-right font-weight-bold">
                    {data.invoiceNo.substring(3)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row invoice-info">
            <div className="col-6 mt-1">
              <h6 className="invoice-from font-weight-bold">Bill To:</h6>
              <div>
                <span className="font-weight-bold text-uppercase">
                  {__tempBillToCompany}
                </span>
              </div>
              <div>
                <span>{__tempBillToAddress}</span>
              </div>
              <div>
                <span>{__tempBillToEmail}</span>
              </div>
              <div>
                <span>{__tempBillToPhoneNumber}</span>
              </div>
            </div>
            <div className="col-6 mt-1">
              <h6 className="invoice-to font-weight-bold">Ship To:</h6>
              <div>
                <span className="font-weight-bold text-uppercase">
                  {__tempBillToCompany}
                </span>
              </div>
              <div>
                <span>{__tempBillToAddress}</span>
              </div>
              <div>
                <span>{__tempBillToEmail}</span>
              </div>
              <div>
                <span>{__tempBillToPhoneNumber}</span>
              </div>
            </div>
          </div>
          <hr />
          <div className="invoice-product-details table-responsive mx-md-25 font-small-3">
            <Table className="table table-sm mb-0">
              <thead className="thead-light">
                <tr className="border-0">
                  <th scope="col">No</th>
                  <th scope="col">Description</th>
                  <th scope="col" className="text-center">
                    Quantity
                  </th>
                  <th scope="col" className="text-center">
                    Unit Price
                  </th>
                  <th scope="col" className="text-right">
                    Amount Excl. Tax (MYR)
                  </th>
                  <th scope="col" className="text-right">
                    Tax Code
                  </th>
                  <th scope="col" className="text-right">
                    Tax Amount
                  </th>
                  <th scope="col" className="text-right">
                    Amount Incl. Tax (MYR)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>{data.description}</td>
                  <td>{data.quantity}</td>
                  <td className="text-center">{data.cost}</td>
                  <td className="text-right">{data.subtotal}</td>
                  <td className="text-right">S0</td>
                  <td className="text-right">{data.taxAmount}</td>
                  <td className="text-primary text-right font-weight-bold">
                    {data.total}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <hr />
          <div className="row">
            <div className="col-12 col-sm-8 mt-80 pt-4 font-small-2">
              <Table className="table table-bordered table-sm mr-3 mb-0">
                <thead>
                  <tr className="font-weight-bold">
                    <td>Tax Code</td>
                    <td>Tax %</td>
                    <td>Nett</td>
                    <td>Tax</td>
                    <td>Gross</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>S0</td>
                    <td>{data.tax}</td>
                    <td>{data.subtotal}</td>
                    <td>{data.taxAmount}</td>
                    <td>{data.subtotal}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="col-12 col-sm-4">
              <ul className="list-group list-group-flush">
                <li className="list-group-item d-flex justify-content-between border-0 pb-0">
                  <span className="invoice-subtotal-title">Subtotal</span>
                  <h6 className="invoice-subtotal-value mb-0">
                    {formatAmount(data.subtotal)}
                  </h6>
                </li>
                <li className="list-group-item d-flex justify-content-between border-0 pb-0">
                  <span className="invoice-subtotal-title">Total Tax</span>
                  <h6 className="invoice-subtotal-value mb-0">
                    {`(${formatPercentage(data.tax)}) ${formatAmount(
                      data.taxAmount
                    )}`}
                  </h6>
                </li>
                <li className="list-group-item py-0 border-0 mt-25">
                  <hr />
                </li>
                <li className="list-group-item d-flex justify-content-between border-0 py-0">
                  <span className="invoice-subtotal-title">Total in MYR</span>
                  <h6 className="invoice-subtotal-value mb-0">
                    {formatAmount(data.total)}
                  </h6>
                </li>
              </ul>
            </div>
          </div>
          <div className="invoice-product-details table-responsive mx-md-25 font-small-3 pt-4">
            <div>
              <span className="font-weight-bold text-uppercase">
                <u>NOTE</u>
              </span>
            </div>
            <div>
              <ol type="a">
                <li>
                  <span>
                    All cheque should be crossed and made payable to “TUNE TALK
                    SDN BHD”
                  </span>
                </li>
                <li>
                  <span>
                    For Giro or Telegraphic Transfer, please remit to:
                    <br />
                  </span>
                  <div className="d-flex flex-wrap">
                    <div className="mr-3 pl-2">
                      <Table width="450px" className="invoice-note">
                        <thead>
                          <tr>
                            <td width="25%">Bank Name</td>
                            <td>:</td>
                            <td width="75%">CIMB Bank Berhad</td>
                          </tr>
                          <tr valign="top">
                            <td width="25%">Bank Address</td>
                            <td>:</td>
                            <td width="75%">
                              KL Main Branch, Menara Bumiputra-Commerce,
                              <br />
                              11, Jalan Raja Laut, 50350 Kuala Lumpur
                            </td>
                          </tr>
                          <tr>
                            <td width="25%">Bank Account no</td>
                            <td>:</td>
                            <td width="75%">8000301243</td>
                          </tr>
                          <tr>
                            <td width="25%">SWIFT Code</td>
                            <td>:</td>
                            <td width="75%">CIBBMYKL</td>
                          </tr>
                        </thead>
                      </Table>
                    </div>
                  </div>
                </li>
                <li>Beneficiary to receive payment in full</li>
                <li>
                  Kindly email us the payment notification at
                  ar.finance@tunetalk.com
                </li>
                <li>
                  This is computer generated invoice, hence no signature
                  required.
                </li>
              </ol>
            </div>
            <div className="float-right pt-5">
              <Table className="customer-acknowledgement">
                <thead>
                  <tr>
                    <td>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td width="300px" align="center">
                      Customer Acknowledgment
                    </td>
                  </tr>
                </thead>
              </Table>
            </div>
          </div>
        </Card.Body>
      </div>
    </WrapperCard>
  );
});

export default InvoiceDetails;
