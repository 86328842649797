import React from 'react';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Loading from '../shared/Loading/Loading';

const UserManagementTable = ({
  data,
  handleShowUserDetailsModal,
  handleUpdateUserStatus,
  handleMakeAdmin,
  handlePageChange,
}) => (
  <div className="col-12 mt-5">
    <Table responsive>
      <thead>
        <tr>
          <th>#ID</th>
          <th>User Name</th>
          <th>Email</th>
          <th>Company Name</th>
          <th>Registered Date</th>
          <th>Payment Type</th>
          <th>Balance</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data.showDataLoading && (
          <tr>
            <td colSpan="9" className="loading-white-bg">
              <Loading />
            </td>
          </tr>
        )}
        {!data.showDataLoading &&
          data.userList.length > 0 &&
          data.userList.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.companyId.name}</td>
              <td>
                {moment(user.createdAt).format('DD MMMM YYYY, h:mm:ss a')}
              </td>
              <td>{user.companyId.postpaid === 1 ? 'Postpaid' : 'Prepaid'}</td>
              <td>
                {user.companyId.postpaid === 1
                  ? 'Unlimited'
                  : `RM ${user.companyId.credit.toLocaleString()}`}
              </td>
              <td className="badge-wrapper">
                <span
                  className={
                    'badge badge-pill ' +
                    (user.status === 'active'
                      ? 'badge-light-success'
                      : user.status === 'admin'
                      ? 'badge-light-primary'
                      : 'badge-light-danger')
                  }
                >
                  {user.status}
                </span>
              </td>
              <td className="user-management-action">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Show Detail</Tooltip>}
                >
                  <i
                    className="bx bx-search-alt-2"
                    onClick={() => handleShowUserDetailsModal(user.id)}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">
                      {user.status === 'active' || user.status === 'admin'
                        ? 'Suspend User'
                        : 'Unsuspend User'}
                    </Tooltip>
                  }
                >
                  <i
                    className={
                      'bx ' +
                      (user.status === 'suspended' ? 'bx-lock-open' : 'bx-lock')
                    }
                    disabled={data.userStatusLoading}
                    onClick={() => handleUpdateUserStatus(user.id, user.status)}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">
                      {user.status === 'admin' ? 'Unmake Admin' : 'Make Admin'}
                    </Tooltip>
                  }
                >
                  <i
                    className={
                      'bx ' +
                      (user.status === 'admin' ? 'bxs-key admin' : 'bxs-key')
                    }
                    disabled={data.userStatusLoading}
                    onClick={() => handleMakeAdmin(user.id, user.status)}
                  />
                </OverlayTrigger>
              </td>
            </tr>
          ))}
        {!data.showDataLoading && data.userList.length === 0 && (
          <tr className="text-center">
            <td colSpan="9">
              <img
                alt="no-data-found"
                src={require('../../assets/shared/no-data.png').default}
              />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
    {data.totalData > 10 && (
      <div className="pagination-custom-style">
        <Pagination
          activePage={data.currentPage}
          itemsCountPerPage={data.perPage}
          totalItemsCount={data.totalData}
          onChange={(e) => handlePageChange(e)}
          hideFirstLastPages
          prevPageText="<PREV"
          nextPageText="NEXT>"
          activeClass="active-page"
          activeLinkClass="active-link"
          itemClass=""
          linkClass="defaul-link"
        />
      </div>
    )}
  </div>
);

export default UserManagementTable;
