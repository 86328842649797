import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Bar, Line } from 'react-chartjs-2';
import { Card, Form } from 'react-bootstrap';
import { getRequest } from '../../helpers/apiHandlers';
import config from '../../config/constant';
import WrapperCard from '../../components/shared/Cards/WrapperCard';
import Loading from '../../components/shared/Loading/Loading';

const monthLabel = config.months;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthlyBarData: {
        labels: [],
        data: [],
        gotData: false,
      },
      weeklyLineData: {
        labels: [],
        data: [],
        total: 0,
        gotData: false,
      },
      biWeeklyLineData: {
        labels: [],
        data: [],
        successPercentage: 0,
        gotData: false,
      },
      costSpendLineData: {
        labels: [],
        data: [],
        costSpend: 0,
        gotData: false,
      },
      isLoading: false,
      backgroundColor: ['#50C878', '#FFA62F'],
      borderColor: ['#50C878', '#FFA62F'],
      borderWidth: 1,
      barChartOptions: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';
                let Label = '';

                const ctx = context.parsed.y;

                Label = `${label}: ${ctx} SMS`;

                return Label;
              },
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
        },
      },
      weeklyChartOptions: {
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
              padding: 20,
            },
          },
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
      },
      lineChartOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
      },
      stackedChartOptions: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';
                let Label = '';

                let ctx = '';

                if (context.parsed.y < 0) {
                  ctx = context.parsed.y *= -1;
                } else {
                  ctx = context.parsed.y;
                }

                Label = `${label}: ${ctx}`;

                return Label;
              },
            },
          },
        },
        scales: {
          x: {
            display: false,
            stacked: true,
          },
          y: {
            display: false,
            stacked: true,
          },
        },
      },
    };

    this.getDashboardData = this.getDashboardData.bind(this);
  }

  componentDidMount() {
    this.getDashboardData();
  }

  graphDataModeling(data) {
    if (Object.keys(data).length !== 0) {
      let tempObject = {
        monthlyObject: {
          labels: [],
          data: [],
          dataFlag: false,
        },
        weeklyObject: {
          labels: [],
          data: [],
          total: 0,
          dataFlag: false,
        },
        biweeklyObject: {
          labels: [],
          data: [],
          successPercentage: 0,
          dataFlag: false,
        },
        costSpendObject: {
          labels: [],
          data: [],
          costSpend: 0,
          dataFlag: false,
        },
      };

      if (Object.keys(data.monthly).length !== 0) {
        const tempMV = [];
        let tempLabel = [],
          mvLabel = [],
          mvObj = {
            mt: [],
            mo: [],
          };

        tempLabel = Object.keys(data.monthly.details).reverse();

        tempMV.push(Object.values(data.monthly.details));

        tempMV[0].map((mv) => {
          mvObj.mt.push(mv.mt);
          mvObj.mo.push(mv.mo);

          return mvObj;
        });

        mvObj.mt = mvObj.mt.reverse();
        mvObj.mo = mvObj.mo.reverse();

        tempLabel.map((m) => {
          monthLabel.map((l) => {
            if (m.substring(5) === l.value) {
              mvLabel.push(l.month);
            }

            return mvLabel;
          });

          return mvLabel;
        });

        tempObject.monthlyObject.labels = mvLabel;
        tempObject.monthlyObject.data = mvObj;
      }

      if (Object.keys(data.weekly).length !== 0) {
        const tempWV = [];
        let tempLabel = [],
          wvLabel = [],
          wvObj = {
            mt: [],
            mo: [],
          };

        tempLabel = Object.keys(data.weekly.details).reverse();

        tempWV.push(Object.values(data.weekly.details));

        tempWV[0].map((mv) => {
          wvObj.mt.push(mv.mt);
          wvObj.mo.push(mv.mo);

          return wvObj;
        });

        wvObj.mt = wvObj.mt.reverse();
        wvObj.mo = wvObj.mo.reverse();

        tempLabel.map((m) => {
          let d = new Date(m);
          wvLabel.push(moment(d).format('ddd'));

          return wvLabel;
        });

        tempObject.weeklyObject.labels = wvLabel;
        tempObject.weeklyObject.data = wvObj;
        tempObject.weeklyObject.total = data.weekly.total;
      }

      if (Object.keys(data.biweekly).length !== 0) {
        const tempBWV = [];
        let tempLabel = [],
          bwLabel = [],
          bwObj = {
            success: [],
            failed: [],
          };

        tempLabel = Object.keys(data.biweekly.details).reverse();

        tempBWV.push(Object.values(data.biweekly.details));

        tempBWV[0].map((mv) => {
          bwObj.success.push(mv.success);
          bwObj.failed.push((mv.failed *= -1));

          return bwObj;
        });

        bwObj.success = bwObj.success.reverse();
        bwObj.failed = bwObj.failed.reverse();

        tempLabel.map((m) => {
          let d = new Date(m);
          bwLabel.push(moment(d).format('ddd'));

          return bwLabel;
        });

        tempObject.biweeklyObject.labels = bwLabel;
        tempObject.biweeklyObject.data = bwObj;
        tempObject.biweeklyObject.successPercentage =
          data.biweekly.successPercentage;
      }

      if (Object.keys(data.costSpend).length !== 0) {
        let tempCV = [],
          tempLabel = [],
          cValue = [],
          cLabel = [],
          csValue = 0;

        tempLabel = Object.keys(data.costSpend.costSpend);
        tempCV.push(Object.values(data.costSpend.costSpend));

        tempLabel.map((l) => {
          if (l !== 'total') {
            cLabel.push('W-' + l);
          }

          return cLabel;
        });

        csValue = tempCV[0][tempCV[0].length - 1];

        cValue = tempCV[0].slice(0, tempCV[0].length - 1);

        tempObject.costSpendObject.labels = cLabel;
        tempObject.costSpendObject.data = cValue;
        tempObject.costSpendObject.costSpend = csValue;
      }

      Object.values(tempObject.monthlyObject.data.mt).map((mt) => {
        if (mt > 0) {
          tempObject.monthlyObject.dataFlag = true;
        }

        return tempObject;
      });

      Object.values(tempObject.monthlyObject.data.mo).map((mo) => {
        if (mo > 0) {
          if (!tempObject.monthlyObject.dataFlag) {
            tempObject.monthlyObject.dataFlag = true;
          }
        }

        return tempObject;
      });

      Object.values(tempObject.weeklyObject.data.mt).map((mt) => {
        if (mt > 0) {
          tempObject.weeklyObject.dataFlag = true;
        }

        return tempObject;
      });

      Object.values(tempObject.weeklyObject.data.mo).map((mo) => {
        if (mo > 0) {
          if (!tempObject.weeklyObject.dataFlag) {
            tempObject.weeklyObject.dataFlag = true;
          }
        }

        return tempObject;
      });

      Object.values(tempObject.biweeklyObject.data.success).map((s) => {
        if (s > 0) {
          tempObject.biweeklyObject.dataFlag = true;
        }

        return tempObject;
      });

      Object.values(tempObject.biweeklyObject.data.success).map((f) => {
        if (f > 0) {
          if (!tempObject.biweeklyObject.dataFlag) {
            tempObject.biweeklyObject.dataFlag = true;
          }
        }

        return tempObject;
      });

      return tempObject;
    }
  }

  getDashboardData() {
    this.setState({ isLoading: true });

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    };

    getRequest('/dashboard', data, (res) => {
      const graphData =
        Object.keys(res.data).length !== 0
          ? this.graphDataModeling(res.data)
          : {};

      if (res.success) {
        this.setState((prevState) => ({
          monthlyBarData: {
            ...prevState.monthlyBarData,
            labels: graphData.monthlyObject.labels,
            data: graphData.monthlyObject.data,
            gotData: graphData.monthlyObject.dataFlag,
          },
          weeklyLineData: {
            ...prevState.weeklyLineData,
            labels: graphData.weeklyObject.labels,
            data: graphData.weeklyObject.data,
            total: graphData.weeklyObject.total,
            gotData: graphData.weeklyObject.dataFlag,
          },
          biWeeklyLineData: {
            ...prevState.biWeeklyLineData,
            labels: graphData.biweeklyObject.labels,
            data: graphData.biweeklyObject.data,
            successPercentage: graphData.biweeklyObject.successPercentage,
            gotData: graphData.biweeklyObject.dataFlag,
          },
          costSpendLineData: {
            ...prevState.costSpendLineData,
            labels: graphData.costSpendObject.labels,
            data: graphData.costSpendObject.data,
            costSpend: graphData.costSpendObject.costSpend,
          },
          isLoading: false,
        }));
      } else {
        // console.log(res);
      }
    });
  }

  render() {
    return (
      <div className="Dashboard">
        <div className="row">
          <div className="col-12 col-md-6">
            <WrapperCard
              header={{
                exist: false,
              }}
            >
              <Card.Body>
                <Form.Group>
                  <h4>Monthly SMS Statistics</h4>
                </Form.Group>
                {this.state.isLoading && (
                  <div className="loading-white-bg mt-5 pt-5">
                    <Loading />
                  </div>
                )}
                {!this.state.isLoading && this.state.monthlyBarData.gotData && (
                  <Bar
                    data={{
                      labels: this.state.monthlyBarData.labels,
                      datasets: [
                        {
                          label: 'Mobile Terminated',
                          data: this.state.monthlyBarData.data.mt,

                          backgroundColor: '#50C878',
                          borderColor: '#50C878',
                          barThickness: 5,
                          borderRadius: 5,
                        },
                        {
                          label: 'Mobile Originated',
                          data: this.state.monthlyBarData.data.mo,
                          backgroundColor: '#FFA62F',
                          borderColor: '#FFA62F',
                          barThickness: 5,
                          borderRadius: 5,
                        },
                      ],
                    }}
                    options={this.state.barChartOptions}
                  />
                )}
                {!this.state.isLoading && !this.state.monthlyBarData.gotData && (
                  <div className="fallback-img-container">
                    <div className="row">
                      <div className="col-12">
                        <h6>
                          SMS statistics will be available once you start
                          sending messages.
                        </h6>
                      </div>
                      <div className="col-12">
                        <img
                          alt="no-monthly-data"
                          src={
                            require('../../assets/dashboard/data-report.svg')
                              .default
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Card.Body>
            </WrapperCard>
          </div>
          <div className="col-12 col-md-3">
            <WrapperCard
              header={{
                exist: false,
              }}
            >
              <Card.Body>
                <Form.Group className="text-center">
                  <h2>{this.state.weeklyLineData.total.toLocaleString()}</h2>
                  <span>Total Weekly SMS</span>
                </Form.Group>
                {this.state.isLoading && (
                  <div className="loading-white-bg pt-5">
                    <Loading />
                  </div>
                )}
                {!this.state.isLoading && this.state.weeklyLineData.gotData && (
                  <Line
                    data={{
                      labels: this.state.weeklyLineData.labels,
                      datasets: [
                        {
                          label: 'Mobile Terminated',
                          data: this.state.weeklyLineData.data.mt,

                          backgroundColor: '#50C878',
                          borderColor: '#50C878',
                          barThickness: 5,
                          borderRadius: 5,
                          tension: 0.4,
                        },
                        {
                          label: 'Mobile Originated',
                          data: this.state.weeklyLineData.data.mo,
                          backgroundColor: '#FFA62F',
                          borderColor: '#FFA62F',
                          barThickness: 5,
                          borderRadius: 5,
                        },
                      ],
                    }}
                    options={this.state.weeklyChartOptions}
                  />
                )}
                {!this.state.isLoading && !this.state.weeklyLineData.gotData && (
                  <div className="fallback-img-container">
                    <div className="row">
                      <div className="col-12">
                        <h6>
                          Weekly SMS will be available once you start sending
                          messages.
                        </h6>
                      </div>
                      <div className="col-12">
                        <img
                          alt="no-weekly-data"
                          src={
                            require('../../assets/dashboard/visual-data.svg')
                              .default
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Card.Body>
            </WrapperCard>
          </div>
          <div className="col-12 col-md-3">
            <div className="row">
              <div className="col-12">
                <WrapperCard
                  header={{
                    exist: false,
                  }}
                >
                  <Card.Body>
                    <Form.Group>
                      <h4>Success Rate</h4>
                      <h6>{`${this.state.biWeeklyLineData.successPercentage.toFixed(
                        2
                      )}% (from past ${
                        this.state.biWeeklyLineData.labels.length
                      } days)`}</h6>
                    </Form.Group>
                    {this.state.isLoading && (
                      <div className="loading-white-bg mt-4 pt-5">
                        <Loading />
                      </div>
                    )}
                    {!this.state.isLoading &&
                      this.state.biWeeklyLineData.gotData && (
                        <Bar
                          data={{
                            labels: this.state.biWeeklyLineData.labels,
                            datasets: [
                              {
                                label: 'Success',
                                data: this.state.biWeeklyLineData.data.success,
                                backgroundColor: '#50C878',
                                borderColor: '#50C878',
                                barThickness: 5,
                                borderRadius: 5,
                              },
                              {
                                label: 'Failed',
                                data: this.state.biWeeklyLineData.data.failed,
                                backgroundColor: '#FF0000',
                                borderColor: '#FF0000',
                                barThickness: 5,
                                borderRadius: 5,
                              },
                            ],
                          }}
                          options={this.state.stackedChartOptions}
                        />
                      )}
                    {!this.state.isLoading &&
                      !this.state.biWeeklyLineData.gotData && (
                        <div className="fallback-img-container">
                          <div className="row">
                            <div className="col-12">
                              <h6>
                                Success rate will be available once you start
                                sending messages.
                              </h6>
                            </div>
                            <div className="col-12">
                              <img
                                alt="no-biweekly-data"
                                src={
                                  require('../../assets/dashboard/report.svg')
                                    .default
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                  </Card.Body>
                </WrapperCard>
              </div>
              <div className="col-12">
                <WrapperCard
                  header={{
                    exist: false,
                  }}
                >
                  <Card.Body id="cost-spend-card">
                    <div className="row">
                      <div className="col-6">
                        <div className="total-wrapper">
                          <div className="avatar mr-2">
                            <div className="avatar-content">
                              <i className="bx bx-wallet text-primary" />
                            </div>
                          </div>
                          <div className="total-amount">
                            <h6 className="mb-0 ml-0">{`RM ${this.state.costSpendLineData.costSpend.toLocaleString()}`}</h6>
                            <small className="text-muted">
                              Cost Spent
                              <br />
                              {`(${moment()
                                .subtract(1, 'months')
                                .startOf('month')
                                .format('MMMM')})`}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        {this.state.isLoading && (
                          <div className="loading-white-bg mt-4">
                            <Loading />
                          </div>
                        )}
                        {!this.state.isLoading && (
                          <Line
                            data={{
                              labels: this.state.costSpendLineData.labels,
                              datasets: [
                                {
                                  label: 'Cost (RM)',
                                  data: this.state.costSpendLineData.data,
                                  backgroundColor: '#6698FF',
                                  borderColor: '#6698FF',
                                  barThickness: 5,
                                  borderRadius: 5,
                                  tension: 0.5,
                                },
                              ],
                            }}
                            options={this.state.lineChartOptions}
                          />
                        )}
                      </div>
                    </div>
                  </Card.Body>
                </WrapperCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Dashboard);
