import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import Select from 'react-select';
import FileSaver from 'file-saver';
import { Card, Form } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import config from '../../config/constant';
import { getRequest } from '../../helpers/apiHandlers';
import { getUserCredits } from '../../store/actions/authAction';
import WrapperCard from '../../components/shared/Cards/WrapperCard';
import HOC from '../../components/shared/HOC';
import Loading from '../../components/shared/Loading/Loading';
import ReportSummary from '../../components/Reports/ReportSummary';
import ReportTable from '../../components/Reports/ReportTable';

const API_BASE_URL = config.urls.api_base_url;
const STATUS_LIST = config.messageStatus;

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shortCodeFilterList: [],
      resultFilterList: STATUS_LIST,
      startDate: null,
      endDate: null,
      dateValue: '',
      selectedShortCode: [],
      selectedResult: [],
      messageID: '',
      destinationMSISDN: '',
      messageContain: '',
      currentPage: 1,
      perPage: 10,
      totalData: 0,
      reportDataList: [],
      summaryData: {},
      isLoading: false,
      isSummaryLoading: false,
      isDownloadLoading: false,
    };

    this.getShortCodes = this.getShortCodes.bind(this);
    this.getReportData = this.getReportData.bind(this);
    this.getSummaryData = this.getSummaryData.bind(this);
    this.handleDownloadReport = this.handleDownloadReport.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.handleSelectShortCode = this.handleSelectShortCode.bind(this);
    this.handleSelectResult = this.handleSelectResult.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.getShortCodes();
  }

  dataModeling(data) {
    if (data.length > 0) {
      let tempData = [];

      data.map((item) =>
        tempData.push({
          ...item,
          label: item.shortcode,
          value: item.shortcode,
        })
      );

      return tempData;
    }
  }

  getShortCodes() {
    this.setState({ isLoading: true });

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    };

    getRequest('/shortcodes', data, (res) => {
      const shortCodeList =
        res.data.length > 0 ? this.dataModeling(res.data) : [];

      if (res.success) {
        this.setState(
          {
            shortCodeFilterList: shortCodeList,
          },
          () => {
            this.props.getUserCredits(this.props.auth.token);
            this.getReportData();
          }
        );
      } else {
        // console.log(res);
        this.setState({ isLoading: false });
      }
    });
  }

  getReportData(showData) {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
    }

    const shortCodeArray = [],
      resultArray = [];
    let shortCodeString = '',
      resultString = '';

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
      params: {
        page: showData ? 1 : this.state.currentPage,
        perPage: this.state.perPage,
      },
    };

    if (this.state.startDate !== null && this.state.endDate !== null) {
      data.params.startDate = moment(this.state.startDate).toISOString();
      data.params.endDate = moment(this.state.endDate).toISOString();
    }

    if (this.state.selectedShortCode.length > 0) {
      this.state.selectedShortCode.map((shortcode) =>
        shortCodeArray.push(shortcode.value.toString())
      );
    }

    shortCodeString = shortCodeArray.join(',');

    if (shortCodeString.trim() !== '') {
      data.params.shortcode = shortCodeString;
    }

    if (this.state.selectedResult.length > 0) {
      this.state.selectedResult.map((result) => resultArray.push(result.value));
    }

    resultString = resultArray.join(',');

    if (resultString.trim() !== '') {
      data.params.result = resultString;
    }

    if (this.state.messageID.trim() !== '') {
      data.params.messageId = this.state.messageID;
    }

    if (this.state.destinationMSISDN.trim() !== '') {
      data.params.msisdn = this.state.destinationMSISDN;
    }

    if (this.state.messageContain.trim() !== '') {
      data.params.contain = this.state.messageContain;
    }

    getRequest('/reports', data, (res) => {
      if (res.success) {
        this.setState(
          {
            reportDataList: res.data.data.contains,
            currentPage: res.data.pagination.currentPage,
            perPage: res.data.pagination.perPage,
            totalData: res.data.pagination.totalData,
            isLoading: false,
          },
          () => {
            this.getSummaryData();
          }
        );
      } else {
        // console.log(res);
      }
    });
  }

  getSummaryData() {
    if (!this.state.isSummaryLoading) {
      this.setState({ isSummaryLoading: true });
    }

    const shortCodeArray = [],
      resultArray = [];
    let shortCodeString = '',
      resultString = '';

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
      params: {},
    };

    if (this.state.startDate !== null && this.state.endDate !== null) {
      data.params.startDate = moment(this.state.startDate).toISOString();
      data.params.endDate = moment(this.state.endDate).toISOString();
    }

    if (this.state.selectedShortCode.length > 0) {
      this.state.selectedShortCode.map((shortcode) =>
        // shortCodeArray.push(shortcode.id.toString())
        shortCodeArray.push(shortcode.value)
      );
    }

    shortCodeString = shortCodeArray.join(',');

    if (shortCodeString.trim() !== '') {
      data.params.shortcode = shortCodeString;
    }

    if (this.state.selectedResult.length > 0) {
      this.state.selectedResult.map((result) => resultArray.push(result.value));
    }

    resultString = resultArray.join(',');

    if (resultString.trim() !== '') {
      data.params.result = resultString;
    }

    if (this.state.messageID.trim() !== '') {
      data.params.messageId = this.state.messageID;
    }

    if (this.state.destinationMSISDN.trim() !== '') {
      data.params.msisdn = this.state.destinationMSISDN;
    }

    if (this.state.messageContain.trim() !== '') {
      data.params.contain = this.state.messageContain;
    }

    getRequest('/report/summary', data, (res) => {
      if (res.success) {
        this.setState({
          summaryData: res.data,
          isSummaryLoading: false,
        });
      } else {
        // console.log(res);
        this.setState({ isSummaryLoading: false });
      }
    });
  }

  handleDownloadReport() {
    this.setState({ isDownloadLoading: true });

    const shortCodeArray = [],
      resultArray = [];
    let shortCodeString = '',
      resultString = '';

    const data = {
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
      params: {},
    };

    if (this.state.startDate !== null && this.state.endDate !== null) {
      data.params.startDate = moment(this.state.startDate).toISOString();
      data.params.endDate = moment(this.state.endDate).toISOString();
    }

    if (this.state.selectedShortCode.length > 0) {
      this.state.selectedShortCode.map((shortcode) =>
        // shortCodeArray.push(shortcode.id.toString())
        shortCodeArray.push(shortcode.value)
      );
    }

    shortCodeString = shortCodeArray.join(',');

    if (shortCodeString.trim() !== '') {
      data.params.shortcode = shortCodeString;
    }

    if (this.state.selectedResult.length > 0) {
      this.state.selectedResult.map((result) => resultArray.push(result.value));
    }

    resultString = resultArray.join(',');

    if (resultString.trim() !== '') {
      data.params.result = resultString;
    }

    if (this.state.messageID.trim() !== '') {
      data.params.messageId = this.state.messageID;
    }

    if (this.state.destinationMSISDN.trim() !== '') {
      data.params.msisdn = this.state.destinationMSISDN;
    }

    if (this.state.messageContain.trim() !== '') {
      data.params.contain = this.state.messageContain;
    }

    axios
      .get(`${API_BASE_URL}/reports/download`, data)
      .then((response) => {
        const blobData = new Blob([response.data], {
          type: 'data:text/csv;charset=utf-8,',
        });

        FileSaver.saveAs(blobData, 'message-report.csv');
        this.setState({ isDownloadLoading: false });
      })
      .catch((err) => {
        // console.log(err);
        this.setState({ isDownloadLoading: false });
      });
  }

  handleUserInput(e) {
    const { id, value } = e.target;

    this.setState({ [id]: value });
  }

  handleDatePicker(e, p) {
    if (e.type === 'apply') {
      const dateValue = p.element.val(
        p.startDate.format('DD/MM/YYYY hh:mm A') +
          ' - ' +
          p.endDate.format('DD/MM/YYYY hh:mm A')
      );

      this.setState({
        dateValue: dateValue,
        startDate: p.startDate,
        endDate: p.endDate,
      });
    }

    if (e.type === 'cancel') {
      const dateValue = p.element.val('');

      this.setState({
        dateValue: dateValue,
        startDate: null,
        endDate: null,
      });
    }
  }

  handleSelectShortCode(e) {
    this.setState({ selectedShortCode: e });
  }

  handleSelectResult(e) {
    this.setState({ selectedResult: e });
  }

  handlePageChange(pageNo) {
    this.setState(
      {
        currentPage: pageNo,
      },
      () => {
        this.getReportData();
      }
    );
  }

  render() {
    return (
      <div className="Reports">
        <div className="row">
          <div className="col-12">
            <WrapperCard
              header={{
                exist: true,
                data: {
                  iconName: 'bx bx-file',
                  title: 'Messaging Report',
                },
              }}
            >
              <Card.Body>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Form.Group controlId="dateRange">
                      <Form.Label>Date & Time Range</Form.Label>
                      <DateRangePicker
                        initialSettings={{
                          timePicker: true,
                          locale: {
                            cancelLabel: 'Clear',
                            format: 'DD/MM/YYYY hh:mm A',
                          },
                          autoUpdateInput: false,
                        }}
                        onEvent={(event, picker) =>
                          this.handleDatePicker(event, picker)
                        }
                      >
                        <Form.Control
                          type="text"
                          className="form-control datetime"
                          placeholder="Select Date & Time"
                          aria-invalid="false"
                          autoComplete="off"
                        />
                      </DateRangePicker>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Form.Label>Filter By</Form.Label>
                    <Form.Group controlId="shortCodeFilter">
                      <Select
                        isMulti
                        name="filterBy"
                        placeholder="Select Shortcode"
                        options={this.state.shortCodeFilterList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        styles={{
                          multiValue: (base) => ({
                            ...base,
                            backgroundColor: '#dd0613',
                            color: '#fff',
                          }),
                          multiValueLabel: (base) => ({
                            ...base,
                            color: '#fff',
                          }),
                        }}
                        onChange={(e) => this.handleSelectShortCode(e)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Group
                      controlId="resultFilter"
                      className="result-filter"
                    >
                      <Select
                        isMulti
                        name="filterBy"
                        placeholder="Select Status"
                        options={this.state.resultFilterList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        styles={{
                          multiValue: (base) => ({
                            ...base,
                            backgroundColor: '#dd0613',
                            color: '#fff',
                          }),
                          multiValueLabel: (base) => ({
                            ...base,
                            color: '#fff',
                          }),
                        }}
                        onChange={(e) => this.handleSelectResult(e)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Group controlId="messageID">
                      <Form.Control
                        placeholder="Message ID"
                        type="text"
                        onChange={(e) => this.handleUserInput(e)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Group controlId="destinationMSISDN">
                      <Form.Control
                        placeholder="MSISDN"
                        type="number"
                        onChange={(e) => this.handleUserInput(e)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Group controlId="messageContain">
                      <Form.Control
                        placeholder="SMS Content"
                        type="text"
                        onChange={(e) => this.handleUserInput(e)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-12 d-flex flex-sm-row flex-column justify-content-center">
                    <button
                      type="submit"
                      className="btn main-btn"
                      onClick={() => this.getReportData(true)}
                      disabled={this.state.isLoading}
                    >
                      {this.state.isLoading ? <Loading /> : <span>Apply</span>}
                    </button>
                  </div>
                  <ReportTable
                    data={this.state}
                    handlePageChange={this.handlePageChange}
                  />
                  <div className="col-12 d-flex flex-sm-row flex-column">
                    <button
                      type="submit"
                      className="btn main-btn icon-btn"
                      onClick={() => this.handleDownloadReport()}
                      disabled={this.state.isDownloadLoading}
                    >
                      {this.state.isDownloadLoading ? (
                        <Loading />
                      ) : (
                        <HOC>
                          <i className="bx bx-download " />
                          <span>Download report</span>
                        </HOC>
                      )}
                    </button>
                  </div>
                  <ReportSummary data={this.state} />
                </div>
              </Card.Body>
            </WrapperCard>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getUserCredits: (token) => dispatch(getUserCredits(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
