import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Page404 from '../components/shared/Page404/Page404';
import Invoice from '../layouts/AdminTools/Invoice/Invoice';
import ViewInvoice from '../layouts/AdminTools/Invoice/ViewInvoice';
import EditInvoice from '../layouts/AdminTools/Invoice/EditInvoice';

const baseUrl = process.env.PUBLIC_URL;

const Routes = () => (
  <div className="Invoice">
    <Switch>
      <Redirect
        exact
        from={`${baseUrl}/dashboard/admin-tools`}
        to={`${baseUrl}/dashboard/admin-tools/invoice`}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/dashboard/admin-tools/invoice`}
        component={Invoice}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/dashboard/admin-tools/invoice/view-invoice/:id`}
        component={ViewInvoice}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/dashboard/admin-tools/invoice/edit-invoice/:id`}
        component={EditInvoice}
      />
      <Route path="*" component={Page404} />
    </Switch>
  </div>
);

export default Routes;
